import { errors } from '../constants'

const API_URL = `${process.env.REACT_APP_API_HOST}/api/${process.env.REACT_APP_API_VERSION}/`

const SUCCESS_STATUS = [200, 201, 203, 204, 300, 301, 302, 303, 304]

const apiFetch = async (url, options, query = '') => {
    try {
        const result = await fetch(API_URL + url + (query || ''), options)
        if (result.status === 403) {
            const { error } = await result.json()
            localStorage.clear()
            window.location.href = '/'
            return { error }
        }
        if (result.status === 404) {
            throw Error('API not found')
        }
        const json = await result?.json()
        if (!SUCCESS_STATUS.includes(result.status)) {
            if (json?.error && typeof json?.error === 'string') {
                return { error: json?.error }
            } else if (json?.message && typeof json?.message === 'string' && json?.message.includes('Validation')) {
                let invalidField = json.message.split(' value ')?.[2]
                invalidField = invalidField?.split(' at ')?.[0]
                invalidField = invalidField?.replaceAll(' ', '')
                return { error: `Validation error, please check your inputs: ${invalidField}`, validation: invalidField }
            }
            return { error: errors.SERVER }
        }
        return json
    } catch (e) {
        // Error handling
        console.error(e)
        return { error: errors.FAILED_FETCH }
    }
}

/**
 *
 * @param {String} url - Final path string for API call - No domain or API version required
 * @param {Object} data - Data object to send in body of request
 */
export const apiPost = (url, data, query = '') => {
    const userPersist = JSON.parse(localStorage.getItem('persist:employer'))
    let user
    if (userPersist?.user) {
        user = JSON.parse(userPersist.user)
    }
    return apiFetch(
        url,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`,
            },
            body: JSON.stringify(data),
        },
        query
    )
}

/**
 *
 * @param {String} url - Final path string for API call - No domain or API version required
 * @param {Object} data -  Data object to send as key=value in query string
 */

export const apiGet = (url, data = {}, token) => {
    // TODO: Send employer id through smartly
    // const allData = {
    //     employerId: '',
    //     ...data,
    // }
    const query = Object.keys(data).reduce((acc, key, idx) => {
        acc += idx === 0 ? '?' : '&'
        // eslint-disable-next-line no-multi-assign
        const queryString = (acc += `${encodeURI(key)}=${encodeURI(data[key])}`)
        return queryString
    }, '')
    const userPersist = JSON.parse(localStorage.getItem('persist:employer'))
    let user
    if (userPersist?.user) {
        user = JSON.parse(userPersist.user)
    }
    return apiFetch(
        url,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token || token}`,
            },
        },
        query
    )
}

export const apiPut = (url, data = {}, query = '') => {
    const userPersist = JSON.parse(localStorage.getItem('persist:employer'))
    let user
    if (userPersist?.user) {
        user = JSON.parse(userPersist.user)
    }

    return apiFetch(
        url,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`,
            },
            body: JSON.stringify(data),
        },
        query
    )
}

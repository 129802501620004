// Get dashboard info place in state
import { createSlice } from '@reduxjs/toolkit'
import { setAppError } from './appSlice'
import { apiGet } from '../../utils/api'

var startDate = new Date()
startDate.setDate(startDate.getDate() - 30)

const initialState = {
    serviceRequests: [],
    count: 0,
    isServiceRequestsLoading: false,
    serviceRequestTableSearch: {
        search: '',
        jobNumberId: '',
        reasonId: '',
        statusId: '',
        startDate: startDate.toISOString().split('T')[0],
    },
    paginationDefaultPage: 1,
    paginationPerPage: 25,
}

// job number status search
export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setServiceRequests: (state, action) => {
            state.serviceRequests = action.payload?.serviceRequests || state.serviceRequests
            state.count = action.payload?.count?.filteredCount || state.count
        },
        setServiceRequestsLoading: (state, action) => {
            state.isServiceRequestsLoading = action.payload
        },
        setServiceRequestTableSearch: (state, action) => {
            state.serviceRequestTableSearch = { ...state.serviceRequestTableSearch, ...action.payload }
        },
        resetServiceRequestTableSearch: (state, action) => {
            state.serviceRequestTableSearch = { ...initialState.serviceRequestTableSearch }
        },
        setPaginationDefaults: (state, action) => {
            state.paginationDefaultPage = action.payload.paginationDefaultPage || state.paginationDefaultPage
            state.paginationPerPage = action.payload.paginationPerPage || state.paginationPerPage
        },
        clearDashboard: (state) => {
            return initialState
        },
    },
})

export const { setServiceRequests, setServiceRequestsLoading, setServiceRequestTableSearch, clearDashboard, setPaginationDefaults, resetServiceRequestTableSearch } = dashboardSlice.actions

// Selectors

export const selectServiceRequests = (state) => state.dashboard.serviceRequests
export const selectServiceRequestCount = (state) => state.dashboard.count
export const selectIsServiceRequestsLoading = (state) => state.dashboard.isServiceRequestsLoading
export const selectServiceRequestTableSearch = (state) => state.dashboard.serviceRequestTableSearch
export const paginationDefaultPage = (state) => state.dashboard.paginationDefaultPage
export const paginationPerPage = (state) => state.dashboard.paginationPerPage

// Thunks

export const $getServiceRequests =
    (options, cb = () => {}) =>
    async (dispatch, getState) => {
        try {
            let state = getState()
            let query = {
                search: '',
                employerId: state.employer?.selectedLocation?.companyId || '',
                jobNumber: '',
                projectId: '',
                reasonId: '',
                statusId: '',
                startDate: '',
                skip: 0,
                limit: 100,
                ...options,
            }
            const data = await apiGet(`protected/employer/dashboard/data`, query)
            const count = await apiGet(`protected/employer/dashboard/counts`, query)
            dispatch(setServiceRequests({ ...data, count }))
        } catch (e) {
            dispatch(setAppError({ error: e.message || 'Error' }))
        } finally {
            dispatch(setServiceRequestsLoading(false))
            cb()
        }
    }

export default dashboardSlice.reducer

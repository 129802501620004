const { DateTime } = require('luxon')

const fromUsdToFloat = (val) => parseFloat(val.replace(/[$,\s]/g, ''))

const toMaskedEmail = (email) => {
    const [user, domain] = `${email}`.split('@')

    let userMask = user.replaceAll(/./g, '*')
    if (userMask.length === 2) {
        userMask = userMask.replace('*', user.at(0))
    } else if (userMask.length > 2) {
        userMask = user.at(0) + userMask.substring(2) + user.at(user.length - 1)
    }

    const domainParts = domain.split('.')
    const topLevelDomain = domainParts.pop()
    const subdomain = domainParts.join('.')
    let subdomainMask = subdomain.replaceAll(/./g, '*')
    if (subdomainMask.length === 2) {
        subdomainMask = subdomainMask.replace('*', subdomain.at(0))
    } else if (subdomainMask.length > 2) {
        subdomainMask = subdomain.at(0) + subdomainMask.substring(2) + subdomain.at(subdomain.length - 1)
    }

    return `${userMask}@${subdomainMask}.${topLevelDomain}`
}

const toMaskedPhone = (phone) => {
    const maskedPhone = `${phone}`.replace(/\d/g, '*').substring(2)
    const phoneSuffix = phone.substring(phone.length - 2)
    return `${maskedPhone}${phoneSuffix}`
}

const toSeconds = (val) => DateTime.fromISO(val).toSeconds()
const toIsoShortDate = (val) => new Date(val).toISOString().split('T')[0]
const toSsnHyphenated = (val) =>
    val.includes('-')
        ? val
        : val
              .match(/(\d{3})(\d{2})(\d{4})/)
              .splice(1)
              .join('-')
const toUnhyphenated = (val) => val.replaceAll(/-/g, '')
const toUsCentralShortDate = (val) => DateTime.fromJSDate(new Date(val)).setZone('US/Central').toLocaleString(DateTime.DATE_SHORT)
const toUtcShortDate = (val) => DateTime.fromJSDate(new Date(val)).toUTC().toLocaleString(DateTime.DATE_SHORT)
const toUsd = (val) =>
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(val)

module.exports = {
    fromUsdToFloat,
    toMaskedEmail,
    toMaskedPhone,
    toSeconds,
    toIsoShortDate,
    toSsnHyphenated,
    toUnhyphenated,
    toUsCentralShortDate,
    toUsd,
    toUtcShortDate,
}

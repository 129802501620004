import React, { useState } from 'react'
import { apiPut } from '../../utils/api'
import { enums } from '@primed/util-client'
import { useDispatch } from 'react-redux'

// Redux
import { setAppError } from '../../redux/slices/appSlice'

// Components
import { Button, Spinner } from '@primed/hyper'

// Styles

export default function ProfileContact({ contact, userAccountId }) {
    const dispatch = useDispatch()
    const formattedDob = contact.dateOfBirth ? new Date(contact.dateOfBirth).toISOString().split('T')[0] : ''
    const [firstName, setFirstName] = useState(contact.firstName || '')
    const [middleName, setMiddleName] = useState(contact.middleName || '')
    const [lastName, setLastName] = useState(contact.lastName || '')
    const [suffix, setSuffix] = useState(contact.suffix || '')
    const [gender, setGender] = useState(contact.gender || '')
    const [dateOfBirth, setDateOfBirth] = useState(formattedDob || '')
    const [status, setStatus] = useState('read')
    const [original, setOriginal] = useState(contact)

    function onCancel() {
        setFirstName(original.firstName || '')
        setMiddleName(original.middleName || '')
        setLastName(original.lastName || '')
        setSuffix(original.suffix || '')
        setGender(original.gender || '')
        setDateOfBirth(original.dateOfBirth || '')
        setStatus('read')
    }

    const onEdit = () => setStatus('edit')

    async function onSave(event) {
        event.preventDefault()

        try {
            setStatus('load')
            const contact = {
                firstName,
                middleName,
                lastName,
                suffix,
                gender,
                dateOfBirth,
            }
            const payload = {
                userAccountId,
                contact,
            }
            const response = await apiPut('protected/employer/settings/users/profile/contact', payload)

            if (response.error) {
                dispatch(setAppError({ error: response.error }))
                setStatus('edit')
            } else {
                setOriginal(contact)
                setStatus('read')
            }
        } catch (e) {
            setStatus('edit')
        }
    }

    return (
        <>
            <form className="col container card" onSubmit={onSave}>
                <div className="card-header">
                    <div className="d-flex justify-content-between">
                        <h5>Profile</h5>

                        <div>
                            {status === 'read' && <Button title="Edit" cb={onEdit}></Button>}
                            {status === 'edit' && <Button title="Cancel" cb={onCancel}></Button>}
                            {status === 'edit' && <Button type="submit" classes={['btn-outline-success']} title="Save"></Button>}
                            {status === 'load' && (
                                <div className="d-flex align-items-center">
                                    <Spinner />
                                    <span className="ps-1">Saving...</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="card-body">
                    <div>
                        <fieldset disabled={status === 'edit' ? undefined : 'disabled'}>
                            <div className="form-floating mb-3">
                                <input type="text" id="firstName" required="true" className="form-control" placeholder="John" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                <label htmlFor="firstName" className="form-label">
                                    First Name
                                </label>
                            </div>

                            <div className="form-floating mb-3">
                                <input type="text" id="middleName" className="form-control" placeholder="G" value={middleName} onChange={(e) => setMiddleName(e.target.value)} />
                                <label htmlFor="middleName" className="form-label">
                                    Middle Name
                                </label>
                            </div>

                            <div className="form-floating mb-3">
                                <input type="text" id="lastName" required="true" className="form-control" placeholder="Doe" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                <label htmlFor="lastName" className="form-label">
                                    Last Name
                                </label>
                            </div>

                            <div className="form-floating mb-3">
                                <input type="text" id="suffix" className="form-control" placeholder="Jr" value={suffix} onChange={(e) => setSuffix(e.target.value)} />
                                <label htmlFor="suffix" className="form-label">
                                    Suffix
                                </label>
                            </div>

                            <div className="form-floating mb-3">
                                <input type="date" id="dateOfBirth" className="form-control" required="true" value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} />
                                <label htmlFor="suffix" className="form-label">
                                    Date of Birth
                                </label>
                            </div>

                            <div className="form-floating mb-3">
                                <select className="form-select" id="gender" required="true" value={gender} onChange={(e) => setGender(e.target.value)}>
                                    <option value="" disabled>
                                        Select gender
                                    </option>
                                    {Object.entries(enums.LEGACY_SEX).map(([key, val]) => (
                                        <option className="text-capitalize" key={key} value={val}>
                                            {key}
                                        </option>
                                    ))}
                                </select>
                                <label htmlFor="gender">Gender</label>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </form>
        </>
    )
}

import React, { useEffect, useState } from 'react'
import { apiPut } from '../../utils/api'
import { enums } from '@primed/util-client'
import { useDispatch, useSelector } from 'react-redux'
// Redux
import { clearDashboard } from '../../redux/slices/dashboardSlice'
import { setAppError } from '../../redux/slices/appSlice'

// Components
import { Button, Spinner } from '@primed/hyper'
import { $getEmployerOptions } from '../../redux/slices/employerSlice'
import { selectUserId } from '../../redux/slices/userSlice'

// Styles

function ProfileLocation({ location, userAccountId, depth, parentCompanyId, isLastAccessibleLocation, locationAccessChanged, rootUserRole }) {
    const dispatch = useDispatch()

    const loggedInUserId = useSelector(selectUserId)

    const { companyId, companyName, children } = location

    const [accessible, setAccessible] = useState(location.accessible || false)
    const [canViewCaseManagement, setCanViewCaseManagement] = useState(location.canViewCaseManagement == null ? true : !!location.canViewCaseManagement)
    const [role, setRole] = useState('')
    const [cleared, setCleared] = useState(location.notifications?.cleared || false)
    const [notCleared, setNotCleared] = useState(location.notifications?.notCleared || false)
    const [clearedWithRestrictions, setClearedWithRestrictions] = useState(location.notifications?.clearedWithRestrictions || false)
    const [medicallyNotCleared, setMedicallyNotCleared] = useState(location.notifications?.medicallyNotCleared || false)
    const [sentForEvaluation, setSentForEvaluation] = useState(location.notifications?.sentForEvaluation || false)
    const [status, setStatus] = useState('read')
    const [original, setOriginal] = useState(location)
    const [errorCount, setErrorCount] = useState(0)

    useEffect(() => {
        // Setting state on init fixes some linting issues related to variable types
        setRole(location.role || '')
    }, [])

    function onCancel() {
        setErrorCount(errorCount + 1)
        setAccessible(original.accessible || false)
        setRole(original.role || '')
        setCleared(original.notifications?.cleared || false)
        setNotCleared(original.notifications?.notCleared || false)
        setClearedWithRestrictions(original.notifications?.clearedWithRestrictions || false)
        setMedicallyNotCleared(original.notifications?.medicallyNotCleared || false)
        setSentForEvaluation(original.notifications?.sentForEvaluation || false)
        setStatus('read')
    }

    const onEdit = () => setStatus('edit')

    async function onSubmit(event) {
        event.preventDefault()

        try {
            if (!accessible && isLastAccessibleLocation) {
                alert('Removing user access from the last available location is not allowed. Please consider disabling this user account instead.')
                return
            }

            setStatus('load')
            const location = {
                companyId,
                parentCompanyId,
                accessible,
                canViewCaseManagement,
                role: role || null, // Server doesn't accept empty string
                notifications: {
                    cleared,
                    notCleared,
                    clearedWithRestrictions,
                    medicallyNotCleared,
                    sentForEvaluation,
                },
            }
            const payload = {
                userAccountId,
                location,
            }
            const response = await apiPut('protected/employer/settings/users/location', payload)

            if (response.error) {
                dispatch(setAppError({ error: response.error }))
                setStatus('edit')
            } else {
                if (original.accessible !== location.accessible) {
                    locationAccessChanged(location.accessible)
                }
                setOriginal(location)
                setStatus('read')

                // Refresh current employer slice if the current user is being updated
                if (parseInt(userAccountId) === loggedInUserId) {
                    dispatch(clearDashboard())
                    dispatch($getEmployerOptions())
                }
            }
            // When location access changes, execute parent callback function to recompute props values
        } catch (e) {
            setStatus('edit')
        }
    }

    function onCheckAccessible(e) {
        const accessible = e.target.checked
        setAccessible(accessible)

        if (!accessible) {
            setCanViewCaseManagement(true)
            setRole('')
            setCleared(false)
            setNotCleared(false)
            setClearedWithRestrictions(false)
            setMedicallyNotCleared(false)
            setSentForEvaluation(false)
        }
    }

    const formId = `locationForm${companyId}`

    return (
        <>
            <div className="col card">
                <div className="card-header d-flex justify-content-start align-items-center">
                    <input type="checkbox" className="form-check-input me-2" id={`accessible${companyId}`} checked={!!accessible} disabled />
                    <h5>
                        {depth === 0 ? 'Organization' : depth === 1 ? 'Region' : 'Job Site'} - {companyName}
                    </h5>

                    {rootUserRole !== 'admin' || (
                        <div style={{ marginLeft: 'auto', minWidth: '150px', textAlign: 'right' }}>
                            {status === 'read' && <Button classes={['btn-sm']} title="Edit" cb={onEdit}></Button>}
                            {status === 'edit' && <Button classes={['btn-sm']} title="Cancel" cb={onCancel}></Button>}
                            {status === 'edit' && <Button classes={['btn-sm btn-outline-primary']} form={formId} type="submit" title="Save"></Button>}
                            {status === 'load' && (
                                <div className="d-flex align-items-center">
                                    <Spinner />
                                    <span className="ps-1">Saving...</span>
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <form id={formId} className={`card-body ${status === 'edit' ? '' : 'd-none'}`} onSubmit={onSubmit}>
                    <fieldset disabled={status === 'edit' ? undefined : 'disabled'}>
                        <div className="form-check mb-3">
                            <input type="checkbox" className="form-check-input" id={`accessible${companyId}`} checked={!!accessible} onChange={onCheckAccessible} />
                            <label className="form-check-label" htmlFor={`accessible${companyId}`}>
                                Accessible
                            </label>
                        </div>
                    </fieldset>

                    <fieldset disabled={status === 'edit' && accessible ? undefined : 'disabled'}>
                        <div className="form-check mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id={`accessible${companyId}`}
                                checked={!canViewCaseManagement}
                                onChange={(e) => setCanViewCaseManagement(!e.target.checked)}
                            />
                            <label className="form-check-label" htmlFor={`accessible${companyId}`}>
                                HR
                            </label>
                            <i className="bi bi-info-circle ms-2" title="HR roles are unable to view forms or medical records for authorizations having test reasons of Injury or Illness."></i>
                        </div>

                        <div className="form-floating mb-3">
                            <select className="form-select" id={`role${companyId}`} value={role} onChange={(e) => setRole(e.target.value)} required="true">
                                <option value="" disabled>
                                    Select role
                                </option>
                                {Object.entries(enums.PRIMED_EMPLOYER_USER_ROLE).map(([key, val]) => (
                                    <option className="text-capitalize" key={key} value={val}>
                                        {key}
                                    </option>
                                ))}
                            </select>
                            <label htmlFor={`role${companyId}`}>Role</label>
                        </div>

                        <h6>Company Alert Subscriptions</h6>

                        <div className="form-check mb-3">
                            <input type="checkbox" className="form-check-input" id={`cleared${companyId}`} checked={!!cleared} onChange={(e) => setCleared(e.target.checked)} />
                            <label className="form-check-label" htmlFor={`cleared${companyId}`}>
                                Cleared
                            </label>
                        </div>

                        <div className="form-check mb-3">
                            <input type="checkbox" className="form-check-input" id={`notCleared${companyId}`} checked={!!notCleared} onChange={(e) => setNotCleared(e.target.checked)} />
                            <label className="form-check-label" htmlFor={`notCleared${companyId}`}>
                                Not Cleared
                            </label>
                        </div>

                        <div className="form-check mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id={`clearedWithRestrictions${companyId}`}
                                checked={!!clearedWithRestrictions}
                                onChange={(e) => setClearedWithRestrictions(e.target.checked)}
                            />
                            <label className="form-check-label" htmlFor={`clearedWithRestrictions${companyId}`}>
                                Cleared With Restrictions
                            </label>
                        </div>

                        <div className="form-check mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id={`medicallyNotCleared${companyId}`}
                                checked={!!medicallyNotCleared}
                                onChange={(e) => setMedicallyNotCleared(e.target.checked)}
                            />
                            <label className="form-check-label" htmlFor={`medicallyNotCleared${companyId}`}>
                                Medically Not Cleared
                            </label>
                        </div>

                        <div className="form-check mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id={`sentForEvaluation${companyId}`}
                                checked={!!sentForEvaluation}
                                onChange={(e) => setSentForEvaluation(e.target.checked)}
                            />
                            <label className="form-check-label" htmlFor={`sentForEvaluation${companyId}`}>
                                Sent For Evaluation
                            </label>
                        </div>
                    </fieldset>
                </form>

                {children.length > 0 && (
                    <div className="card-footer">
                        <h5>{depth === 0 ? 'Regions' : 'Job Sites'}</h5>
                        {children.map((child) => (
                            <ProfileLocation
                                key={child.companyId}
                                location={child}
                                userAccountId={userAccountId}
                                depth={depth + 1}
                                parentCompanyId={companyId}
                                locationAccessChanged={locationAccessChanged}
                                rootUserRole={rootUserRole}
                            />
                        ))}
                    </div>
                )}
            </div>
        </>
    )
}

export default function ProfileLocationTree({ locationsTree, userAccountId, userAccountActive, rootUserRole }) {
    const [accessibleLocationCount, setAccessibleLocationCount] = useState(0)
    const [isLastAccessibleLocation, setIsLastAccessibleLocation] = useState(false)

    useEffect(() => {
        const accessibleCount = locationsTree.reduce((prev, next) => {
            if (!next.children) {
                return next.accessible ? prev + 1 : prev
            }

            const regionAccessibleCount = next.children.reduce((prevReg, nextReg) => {
                if (!nextReg.children) {
                    return nextReg.accessible ? prevReg + 1 : prevReg
                }
                const jobSiteAccessibleCount = nextReg.children.reduce((prevJob, nextJob) => (nextJob.accessible ? prevJob + 1 : prevJob), 0)
                return jobSiteAccessibleCount + (nextReg.accessible ? prevReg + 1 : prevReg)
            }, 0)

            return regionAccessibleCount + (next.accessible ? prev + 1 : prev)
        }, 0)

        setAccessibleLocationCount(accessibleCount)
        setIsLastAccessibleLocation(accessibleCount === 1)
    }, [])

    function locationAccessChanged(accessible) {
        const change = accessible ? 1 : -1
        const accessibleCount = accessibleLocationCount + change
        setAccessibleLocationCount(accessibleCount)
        setIsLastAccessibleLocation(accessibleCount === 1)
    }

    return (
        <>
            {!userAccountActive && (
                <div className="alert alert-warning" role="alert">
                    <ion-icon name="warning" style={{ position: 'relative', top: '2px' }}></ion-icon>
                    &nbsp;&nbsp;User account must be enabled to manage locations.
                </div>
            )}
            {userAccountActive &&
                locationsTree.map((location) => (
                    <ProfileLocation
                        key={location.companyId}
                        location={location}
                        userAccountId={userAccountId}
                        depth={0}
                        parentCompanyId={null}
                        isLastAccessibleLocation={isLastAccessibleLocation}
                        locationAccessChanged={locationAccessChanged}
                        rootUserRole={rootUserRole}
                    />
                ))}
        </>
    )
}

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { apiGet } from '../../../../utils/api'
import { useNavigate } from 'react-router-dom'

// Redux
import { setAppError } from '../../../../redux/slices/appSlice'
import { selectSelectedLocation } from '../../../../redux/slices/employerSlice'

// Components
import PageTitle from '../../../../components/app/PageTitle'
import ProfileCompanyInformation from '../../../../components/company/ProfileCompanyInformation'
import ProfileContacts from '../../../../components/company/ProfileContacts'
import ProfileExtendedIdentifiers from '../../../../components/company/ProfileExtendedIdentifiers'
import ProfilePayorInformation from '../../../../components/company/ProfilePayorInformation'
import ProfileCompanyProfile from '../../../../components/company/ProfileCompanyProfile'
import { Spinner } from '@primed/hyper'

// Styles

function CompanyProfile(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useSelector(selectSelectedLocation)
    const [companyInfo, setCompanyInfo] = useState({})

    const load = async () => {
        try {
            const info = await apiGet(`protected/employer/settings/profile`, { employerId: location.companyId })
            if (info) {
                setCompanyInfo(info.profile)
            } else {
                dispatch(setAppError({ error: 'Could not load company information.' }))
            }
        } catch (e) {
            dispatch(setAppError({ error: e.error }))
        }
    }

    useEffect(() => {
        if (location.role !== 'admin') {
            navigate('/employer')
        }
        void load()
    }, [])

    return (
        <>
            <PageTitle taskTitle={'Organization Details'} />
            {!!companyInfo.companyId && (
                <>
                    <div className="row justify-content-center">
                        <div className="col-12 mb-3">
                            <ProfileCompanyProfile location={location} companyInfo={companyInfo} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-7">
                            <ProfileCompanyInformation info={companyInfo} reload={load} />
                            <ProfilePayorInformation info={companyInfo} reload={load} />
                            <ProfileExtendedIdentifiers info={companyInfo} reload={load} />
                        </div>
                        <div className="col-5">
                            <ProfileContacts info={companyInfo} reload={load} />
                        </div>
                    </div>
                </>
            )}
            {!!companyInfo.companyId || (
                <div className="mt-2 w-100 text-center">
                    <Spinner />
                </div>
            )}
        </>
    )
}

export default CompanyProfile

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { apiPut } from '../../utils/api'

// Redux
import { setAppError } from '../../redux/slices/appSlice'

// Components
import { Button, Spinner } from '@primed/hyper'

// Styles

function ProfileContacts({ info, reload }) {
    const dispatch = useDispatch()
    const [companyInfo, setCompanyInfo] = useState({})
    const [status, setStatus] = useState('read')
    const [state, setState] = useState({
        hrMainName: '',
        hrMainPhone: '',
        injuryManagementName: '',
        injuryManagementPhone: '',
        responsibleManagerEmailAddress: '',
        responsibleManagerName: '',
        responsibleManagerTitle: '',
        responsibleManagerPhone: '',
        resultsByEmail: false,
        resultsEmailAddress: '',
        resultsCity: '',
        resultsState: '',
        resultsAddress1: '',
        resultsPostalCode: '',
        safetyManagerName: '',
        safetyManagerPhone: '',
    })
    const [validation, setValidation] = useState('')

    useEffect(() => {
        setCompanyInfo(info)
        setState({
            hrMainName: info.hrMainName || '',
            hrMainPhone: info.hrMainPhone || '',
            injuryManagementName: info.injuryManagementName || '',
            injuryManagementPhone: info.injuryManagementPhone || '',
            responsibleManagerName: info.responsibleManagerName || '',
            responsibleManagerEmailAddress: info.responsibleManagerEmailAddress || '',
            responsibleManagerPhone: info.responsibleManagerPhone || '',
            responsibleManagerTitle: info.responsibleManagerTitle || '',
            resultsByEmail: info.resultsEmailResults || '',
            resultsEmailAddress: info.resultsEmailAddress || '',
            resultsCity: info.resultsCity || '',
            resultsState: info.resultsState || '',
            resultsAddress1: info.resultsAddress1 || '',
            resultsPostalCode: info.resultsPostalCode || '',
            safetyManagerPhone: info.safetyManagerPhone || '',
            safetyManagerName: info.safetyManagerName || '',
        })
    }, [info])

    const onEdit = () => setStatus('edit')

    const onSave = async (evt) => {
        evt.preventDefault()
        setStatus('load')
        const response = await apiPut(`protected/employer/settings/profile/detail`, {
            employerId: companyInfo.companyId,
            hrMainName: state.hrMainName || null,
            hrMainPhone: state.hrMainPhone || null,
            injuryManagementName: state.injuryManagementName || null,
            injuryManagementPhone: state.injuryManagementPhone || null,
            responsibleManagerEmailAddress: state.responsibleManagerEmailAddress || null,
            responsibleManagerName: state.responsibleManagerName || null,
            responsibleManagerPhone: state.responsibleManagerPhone || null,
            responsibleManagerTitle: state.responsibleManagerTitle || null,
            resultsEmailAddress: state.resultsEmailAddress || null,
            resultsEmailResults: state.resultsByEmail || false,
            resultsAddress1: state.resultsAddress1 || null,
            resultsCity: state.resultsCity || null,
            resultsState: state.resultsState || null,
            resultsPostalCode: state.resultsPostalCode || null,
            safetyManagerName: state.safetyManagerName || null,
            safetyManagerPhone: state.safetyManagerPhone || null,
        })
        if (response.error) {
            if (response.validation) {
                setValidation(response.validation)
                dispatch(setAppError({ error: response.error }))
            } else {
                dispatch(setAppError({ error: response.error }))
            }
            setStatus('edit')
        } else {
            setValidation('')
            await reload()
            setStatus('read')
        }
    }

    const onCancel = async () => {
        setStatus('read')
        setState({
            hrMainName: companyInfo.hrMainName,
            hrMainPhone: companyInfo.hrMainPhone,
            injuryManagementName: companyInfo.injuryManagementName,
            injuryManagementPhone: companyInfo.injuryManagementPhone,
            responsibleManagerEmailAddress: companyInfo.responsibleManagerEmailAddress,
            responsibleManagerName: companyInfo.responsibleManagerName,
            responsibleManagerTitle: companyInfo.responsibleManagerTitle,
            responsibleManagerPhone: companyInfo.responsibleManagerPhone,
            resultsByEmail: companyInfo.resultsEmailResults,
            resultsEmailAddress: companyInfo.resultsEmailAddress,
            resultsCity: companyInfo.resultsCity,
            resultsState: companyInfo.resultsState,
            resultsAddress1: companyInfo.resultsAddress1,
            resultsPostalCode: companyInfo.resultsPostalCode,
            safetyManagerName: companyInfo.safetyManagerName,
            safetyManagerPhone: companyInfo.safetyManagerPhone,
        })
    }

    const onChange = (event) => {
        const { name, value } = event.target
        if (name === 'resultsByEmail') {
            setState((prevState) => ({
                ...prevState,
                resultsByEmail: !state.resultsByEmail,
            }))
        } else {
            setState((prevState) => ({
                ...prevState,
                [name]: value,
            }))
        }
    }

    return (
        <form onSubmit={onSave}>
            <div className="card">
                <div className="card-header">
                    <div className="d-flex justify-content-between">
                        <h5>Organization Points Of Contact</h5>

                        <div>
                            {status === 'read' && <Button title="Edit" cb={onEdit}></Button>}
                            {status === 'edit' && <Button title="Cancel" cb={onCancel}></Button>}
                            {status === 'edit' && <Button type="submit" classes={['btn-outline-success']} title="Save"></Button>}
                            {status === 'load' && (
                                <div className="d-flex align-items-center">
                                    <Spinner />
                                    <span className="ps-1">Saving...</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="card-body">
                    {!!validation && <p className="small text-danger mt-0 mb-2">Invalid Field: {validation}</p>}
                    <fieldset disabled={status === 'edit' ? undefined : 'disabled'}>
                        <h5 className="mb-2">Results</h5>
                        <div className="row">
                            <div className="form-floating mb-2">
                                <div className="d-flex">
                                    <input className="form-check-input me-2" checked={state?.resultsByEmail} id="results-by-email" name="resultsByEmail" onChange={onChange} type="checkbox" />
                                    <label htmlFor="results-by-email" className="form-label">
                                        Receive Results Through Email
                                    </label>
                                </div>
                            </div>
                            <div>
                                <div className="form-floating mb-2">
                                    <input
                                        type="email"
                                        id="results-email-address"
                                        className="form-control"
                                        required={state?.resultsByEmail}
                                        placeholder="Email"
                                        name="resultsEmailAddress"
                                        value={state?.resultsEmailAddress}
                                        onChange={onChange}
                                    />
                                    <label htmlFor="results-email-address" className="form-label">
                                        Email Address
                                    </label>
                                </div>
                            </div>
                        </div>

                        <hr className="bg-secondary border-1 border-top border-light mb-2" />

                        <h5 className="mb-2">Responsible Manager</h5>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-floating mb-3">
                                    <input
                                        type="email"
                                        id="responsible-manager-email-address"
                                        className="form-control"
                                        placeholder="Email"
                                        required
                                        name="responsibleManagerEmailAddress"
                                        value={state?.responsibleManagerEmailAddress}
                                        onChange={onChange}
                                    />
                                    <label htmlFor="responsible-manager-email-address" className="form-label">
                                        Email
                                    </label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        id="responsible-manager-name"
                                        className="form-control"
                                        placeholder="Name"
                                        required
                                        name="responsibleManagerName"
                                        value={state?.responsibleManagerName}
                                        onChange={onChange}
                                    />
                                    <label htmlFor="responsible-manager-name" className="form-label">
                                        Name
                                    </label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-floating mb-3">
                                    <input
                                        type="tel"
                                        id="responsible-phone"
                                        className="form-control"
                                        placeholder="Phone"
                                        required
                                        name="responsibleManagerPhone"
                                        value={state?.responsibleManagerPhone}
                                        onChange={onChange}
                                    />
                                    <label htmlFor="responsible-manager-phone">Phone</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        id="responsible-manager-title"
                                        className="form-control"
                                        placeholder="Title"
                                        name="responsibleManagerTitle"
                                        value={state?.responsibleManagerTitle}
                                        onChange={onChange}
                                    />
                                    <label htmlFor="responsible-manager-title" className="form-label">
                                        Title
                                    </label>
                                </div>
                            </div>
                        </div>

                        <hr className="bg-secondary border-1 border-top border-light mb-2" />

                        <h5 className="mb-2">Safety Manager</h5>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-floating mb-2">
                                    <input
                                        type="text"
                                        id="safety-manager-name"
                                        className="form-control"
                                        placeholder="Name"
                                        name="safetyManagerName"
                                        value={state?.safetyManagerName}
                                        onChange={onChange}
                                    />
                                    <label htmlFor="safety-manager-name" className="form-label">
                                        Name
                                    </label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-floating mb-2">
                                    <input
                                        type="tel"
                                        id="safety-manager-phone"
                                        className="form-control"
                                        placeholder="Phone"
                                        name="safetyManagerPhone"
                                        value={state?.safetyManagerPhone}
                                        onChange={onChange}
                                    />
                                    <label htmlFor="safety-manager-phone" className="form-label">
                                        Phone
                                    </label>
                                </div>
                            </div>
                        </div>
                        <hr className="bg-secondary border-1 border-top border-light mb-2" />
                        <h5 className="mb-2">Injury Management</h5>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-floating mb-2">
                                    <input
                                        type="text"
                                        id="injury-management-name"
                                        className="form-control"
                                        placeholder="Name"
                                        name="injuryManagementName"
                                        value={state?.injuryManagementName}
                                        onChange={onChange}
                                    />
                                    <label htmlFor="injury-management-name" className="form-label">
                                        Name
                                    </label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-floating mb-2">
                                    <input
                                        type="tel"
                                        id="injury-management-phone"
                                        className="form-control"
                                        placeholder="Phone"
                                        name="injuryManagementPhone"
                                        value={state?.injuryManagementPhone}
                                        onChange={onChange}
                                    />
                                    <label htmlFor="injury-management-phone" className="form-label">
                                        Phone
                                    </label>
                                </div>
                            </div>
                        </div>
                        <hr className="bg-secondary border-1 border-top border-light mb-2" />
                        <h5 className="mb-2">HR Manager</h5>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-floating mb-2">
                                    <input type="text" id="hr-main-name" className="form-control" placeholder="Name" name="hrMainName" value={state?.hrMainName} onChange={onChange} />
                                    <label htmlFor="hr-main-name" className="form-label">
                                        Name
                                    </label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-floating mb-2">
                                    <input type="tel" id="hr-main-phone" className="form-control" placeholder="Phone" name="hrMainPhone" value={state?.hrMainPhone} onChange={onChange} />
                                    <label htmlFor="hr-main-phone" className="form-label">
                                        Phone
                                    </label>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </form>
    )
}

export default ProfileContacts

import React, { useEffect, useRef, useState } from 'react'
import { apiGet } from '../../utils/api'

// Redux
// Components
import { Spinner } from '@primed/hyper'

// Styles
import './TabPatients.scss'
import TabPatientsNewPatient from './TabPatientsNewPatient'
import { useSelector } from 'react-redux'
import { selectSelectedLocation } from '../../redux/slices/employerSlice'

function TabPatients({ serviceRequest, setServiceRequest }) {
    const { companyId: employerId } = useSelector(selectSelectedLocation)

    const [patientType, setPatientType] = useState('existing')
    const [contacts, setContacts] = useState([])
    const [loading, setLoading] = useState(false)
    const [patients, setPatients] = useState([])
    const [showContacts, setShowContacts] = useState(false)
    const searchRef = useRef(null)
    const inputDebounce = useRef(null)

    useEffect(() => {
        document.querySelector('body').addEventListener('click', () => {
            setShowContacts(false)
        })
    }, [])

    const handlePatientSearch = async (e) => {
        const val = e.target.value
        if (val.length >= 3) {
            setContacts([])
            setLoading(true)
            if (inputDebounce.current) {
                clearTimeout(inputDebounce.current)
            }
            inputDebounce.current = setTimeout(async () => {
                if (val.length >= 3) {
                    setShowContacts(true)
                    setLoading(true)
                    const results = await apiGet('protected/employer/contacts/list', { employerId, search: val, limit: 10, skip: 0 })
                    setContacts(results?.contacts || [])
                    setLoading(false)
                } else {
                    setContacts([])
                    setLoading(false)
                }
            }, 1250)
        } else {
            setShowContacts(false)
        }
    }

    const handleAddPatient = (patient) => {
        setPatients([...patients, patient])
        setServiceRequest({ ...serviceRequest, patients: [...patients, patient] })
        setShowContacts(false)
        searchRef.current.value = ''
    }

    const handleRemovePatient = (patient) => {
        setPatients(patients.filter((pat) => pat.patientId !== patient.patientId))
        setServiceRequest({ ...serviceRequest, patients: patients.filter((pat) => pat.patientId !== patient.patientId) })
    }

    return (
        <>
            <div className="row ms-0 mb-2">
                <div className="col-12 mb-3">
                    <button
                        className={`btn me-2 ${patientType === 'existing' ? 'btn-primary' : 'btn-outline-primary'}`}
                        onClick={(e) => {
                            e.preventDefault()
                            setPatientType('existing')
                        }}
                    >
                        Add Existing Employee
                    </button>
                    <button
                        className={`btn me-2 ${patientType === 'new' ? 'btn-primary' : 'btn-outline-primary'}`}
                        onClick={(e) => {
                            e.preventDefault()
                            setPatientType('new')
                        }}
                    >
                        Add New Employee
                    </button>
                </div>
                {patientType === 'existing' && (
                    <div className="col-12 d-flex justify-content-center position-relative">
                        <input
                            ref={searchRef}
                            className="w-100 form-select"
                            placeholder="Search for a previous employee by 'Firstname Lastname' or by Social Security Number"
                            onChange={handlePatientSearch}
                            onClick={(e) => {
                                e.stopPropagation()
                            }}
                        />
                        <div className={`patient-results ${showContacts ? '' : 'd-none'}`}>
                            {loading && (
                                <div className="text-center mt-1 mb-1">
                                    <Spinner />
                                </div>
                            )}
                            {contacts?.map((patient) => {
                                return (
                                    <>
                                        <p className="mt-2 mb-1 cursor-pointer" onClick={() => handleAddPatient(patient)}>
                                            <b>
                                                {patient.firstName} {patient.lastName}
                                            </b>{' '}
                                            - Date of Birth: <b>{patient.dateOfBirth?.split('T')[0]}</b> - Social Security Number:<b> xxx-xx-{patient?.ssn?.substring(5)}</b>
                                        </p>
                                        <hr className="w-100" style={{ border: '.5px solid #ccc' }} />
                                    </>
                                )
                            })}
                            {!contacts?.length && !loading && <p className="mt-2">No contacts found. Please add a new employee.</p>}
                        </div>
                    </div>
                )}
                {patientType === 'new' && <TabPatientsNewPatient serviceRequest={serviceRequest} setServiceRequest={setServiceRequest} patients={patients} setPatients={setPatients} />}
            </div>
            <hr className="mb-2 w-100" style={{ border: '1px solid #ccc' }} />
            <div className="row">
                <div className="table-responsive">
                    <table className="table table-centered table-nowrap mb-4">
                        <thead className="table-light">
                            <tr>
                                <th className="border-0">Name</th>
                                <th className="border-0">Date of Birth</th>
                                <th className="border-0">Social Security Number</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {patients.map((patient) => {
                                return (
                                    <tr key={patient.patientId}>
                                        <td>
                                            {patient.firstName} {patient.lastName}
                                        </td>
                                        <td>{patient.dateOfBirth?.split('T')?.[0]}</td>
                                        <td>xxx-xx-{patient?.ssn?.substring(5)}</td>
                                        <td>
                                            <ion-icon onClick={() => handleRemovePatient(patient)} name="trash"></ion-icon>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default TabPatients

function PageTitle({ taskTitle, children = null }) {
    return (
        <div className="row">
            <div className="col">
                <div className="page-title-box">
                    <div className="page-title-right">{children}</div>
                    <h4 className="page-title">{taskTitle}</h4>
                </div>
            </div>
        </div>
    )
}

export default PageTitle

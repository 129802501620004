import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { $getEmployerOptions, selectEmployerLocations } from '../redux/slices/employerSlice'

// Redux
import { selectBanner, selectToken, selectUsername } from '../redux/slices/userSlice'

// Components
import { Outlet } from 'react-router-dom'
import { Spinner } from '@primed/hyper'
import AppTopNav from '../components/app/AppTopNav'
import AppSideNav from '../components/app/AppSideNav'
import AppToastError from '../components/app/AppToastError'

// Styles
import './App.css'

function App(props) {
    const dispatch = useDispatch()
    const banner = useSelector(selectBanner)
    const username = useSelector(selectUsername)
    const token = useSelector(selectToken)
    const locations = useSelector(selectEmployerLocations)

    useEffect(() => {
        if (token) {
            dispatch($getEmployerOptions())
        }
    }, [token])

    return (
        <>
            {!!locations.length && (
                <>
                    <div data-layout="detached">
                        <AppTopNav username={username} signedIn />

                        {banner === 'true' && (
                            <div id="primed-employer-banner">
                                <span>
                                    Important Notice: New Employer Portal Coming Soon. Click &nbsp;
                                    <a href="/messages/PRIME_Systoc%20Letter.pdf" target="_blank">
                                        HERE <ion-icon icon="exit-outline"></ion-icon>
                                    </a>
                                    &nbsp; for more details.
                                </span>
                            </div>
                        )}

                        {banner != null && banner !== 'true' && <div id="primed-employer-banner" dangerouslySetInnerHTML={{ __html: banner }} />}

                        <button id="btnPrimedEmployerBannerDismiss" type="button" className="btn btn-link" title="Dismiss banner">
                            <i className="fa fa-close" />
                        </button>
                        <div className="container-fluid">
                            <div className="wrapper">
                                <AppSideNav username={username} />

                                <div className="content-page pt-1">
                                    <div className="content">
                                        <Outlet />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <AppToastError />
                </>
            )}
            {!!locations.length || (
                <div className="text-center w-100">
                    {' '}
                    <Spinner classes={['d-inline-block', 'mt-5']} />
                    <AppToastError />
                </div>
            )}
        </>
    )
}

export default App

import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { apiGet } from '../../../../utils/api'

// Redux
import { selectRole, selectUserId } from '../../../../redux/slices/userSlice'
import { setAppError } from '../../../../redux/slices/appSlice'

// Components
import { Spinner } from '@primed/hyper'
import PageTitle from '../../../../components/app/PageTitle'
import ProfileContact from '../../../../components/users/ProfileContact'
import ProfileAddress from '../../../../components/users/ProfileAddress'
import ProfilePhone from '../../../../components/users/ProfilePhone'
import ProfileAccount from '../../../../components/users/ProfileAccount'
import ProfileChangePassword from '../../../../components/users/ProfileChangePassword'
import ProfileLocationTree from '../../../../components/users/ProfileLocationTree'

// Styles

export default function UserProfile() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { userAccountId } = useParams()

    const rootUserRole = useSelector(selectRole)
    const userId = useSelector(selectUserId)
    const isOwnAccount = userId === parseInt(userAccountId)

    const [profile, setProfile] = useState(null)
    const [locationsTree, setLocationsTree] = useState([])
    const [loading, setLoading] = useState(false)

    const currentUser = useRef()

    useEffect(() => {
        if (rootUserRole === 'reader' && !isOwnAccount) {
            dispatch(setAppError({ error: 'You do not have permissions to view this page.' }))
            return navigate('/employer')
        }
        async function init() {
            setLoading(true)

            const { profile } = await apiGet(`protected/employer/settings/users/profile?userAccountId=${userAccountId}`)
            const { locationsTree } = await apiGet(`protected/employer/settings/users/location/tree?userAccountId=${userAccountId}`)
            if (profile) {
                setProfile(profile)
            } else {
                dispatch(setAppError({ error: 'No profile found for this user.' }))
                return navigate('/employer/settings/users')
            }
            // TODO: Error handling
            setLocationsTree(locationsTree)

            setLoading(false)
        }

        if (!profile) {
            void init()
        }
    }, [])

    useEffect(() => {
        if (!currentUser.current) {
            currentUser.current = userAccountId
        } else if (currentUser.current !== userAccountId) {
            window.location.reload()
        }
    }, [userAccountId])

    async function setUserAccount(account) {
        setProfile({
            ...profile,
            account,
        })

        // Don't give access to manage locations while account is inactive.
        if (account.active) {
            const { locationsTree } = await apiGet(`protected/employer/settings/users/location/tree?userAccountId=${userAccountId}`)
            setLocationsTree(locationsTree)
        } else {
            setLocationsTree([])
        }
    }

    function renderData() {
        return (
            <div className="row">
                <div className="col-7">
                    <h4>Profile</h4>
                    <ProfileContact contact={profile.contact} userAccountId={userAccountId} />
                    <ProfileAddress address={profile.address} userAccountId={userAccountId} />
                    <ProfilePhone phone={profile.phone} userAccountId={userAccountId} />
                    <ProfileAccount account={profile.account} userAccountId={userAccountId} setUserAccount={setUserAccount} />
                    {isOwnAccount && <ProfileChangePassword />}
                </div>
                <div className="col-5">
                    <h4>Location Management</h4>
                    {!!locationsTree > 0 && <ProfileLocationTree locationsTree={locationsTree} userAccountId={userAccountId} userAccountActive={profile.account.active} rootUserRole={rootUserRole} />}
                </div>
            </div>
        )
    }

    return (
        <>
            <PageTitle taskTitle={`User Settings${profile?.account?.userName ? ': ' + profile?.account?.userName : ''}`} />
            {loading && (
                <div className="mt-2 w-100 text-center">
                    <Spinner />
                </div>
            )}
            {profile && renderData()}
        </>
    )
}

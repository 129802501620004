import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Redux
import { deleteAppError, selectErrors } from '../../redux/slices/appSlice'

// Components
import { Toast } from '@primed/hyper'

// Styles

function AppToastError() {
    const dispatch = useDispatch()
    const appErrors = useSelector(selectErrors)

    const alertDismiss = (appError) => {
        dispatch(deleteAppError({ error: appError }))
    }

    return (
        <div className="app-error-alert">
            {appErrors?.map((appError, idx) => {
                setTimeout(() => {
                    alertDismiss(appError)
                }, 5000)
                return (
                    <Toast
                        show={true}
                        key={idx}
                        icon="alert-circle-outline"
                        title="An Error Has Occured"
                        copy={typeof appError === 'string' ? appError : 'An error occured.'}
                        color="#d55"
                        dismissCallback={(id) => {
                            alertDismiss(appError)
                        }}
                    ></Toast>
                )
            })}
        </div>
    )
}

export default AppToastError

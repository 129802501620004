const { DateTime } = require('luxon')

const MILLIS_PER_SEC = 1000
const MILLIS_PER_MIN = MILLIS_PER_SEC * 60
const MILLIS_PER_HOUR = MILLIS_PER_MIN * 60
const MILLIS_PER_DAY = MILLIS_PER_HOUR * 24
const MILLIS_PER_WEEK = MILLIS_PER_DAY * 7

const getHoursFromNowISO = (hours) => DateTime.utc().plus({ hours }).toISO()

const getDaysFromNowMillis = (days) => DateTime.utc().plus({ days }).toMillis()

const getDaysFromNow = (days) => DateTime.utc().plus({ days }).toJSDate()

const getDaysFromNowISO = (days) => DateTime.utc().plus({ days }).toISO()

const getNowShortDateString = () => DateTime.utc().toISODate()

const toPaddedWeek = (date) => DateTime.fromJSDate(new Date(date)).toFormat('WW')

const toPaddedMonth = (date) => DateTime.fromJSDate(new Date(date)).toFormat('MM')

module.exports = {
    MILLIS_PER_SEC,
    MILLIS_PER_MIN,
    MILLIS_PER_HOUR,
    MILLIS_PER_DAY,
    MILLIS_PER_WEEK,
    getDaysFromNowMillis,
    getDaysFromNow,
    getHoursFromNowISO,
    getDaysFromNowISO,
    getNowShortDateString,
    toPaddedWeek,
    toPaddedMonth,
}

import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

// Redux
import { setAppError } from '../../redux/slices/appSlice'

// Components
import { Button } from '@primed/hyper'
import { $getServicesData } from '../../redux/slices/serviceRequestSlice'

// Styles

function TabServices({ serviceRequest, setServiceRequest, clinics, reasons, categories, services, location }) {
    const dispatch = useDispatch()
    const [serviceSelectValue, setServiceSelectValue] = useState(null)
    const [selectedServices, setSelectedServices] = useState([])
    const [categorySelectValue, setCategorySelectValue] = useState(null)
    const handleAddService = () => {
        const service = services.filter((s) => s.testTypeId === parseInt(serviceSelectValue))
        if (service.length) {
            setSelectedServices((current) => [{ ...service[0] }, ...current])
            setServiceRequest({ ...serviceRequest, services: [{ ...service[0] }, ...selectedServices] })
            return
        }
        const childServices = services.filter((service) => {
            const { parentTestTypeId } = service
            if (parseInt(categorySelectValue) === parentTestTypeId) {
                return true
            }
            return false
        })
        const category = categories.filter((c) => c.testTypeId === parseInt(categorySelectValue))
        if (category.length && !childServices.length) {
            setSelectedServices((current) => [{ ...category[0] }, ...current])
            setServiceRequest({ ...serviceRequest, services: [{ ...category[0] }, ...selectedServices] })
            return
        }
        dispatch(setAppError({ error: 'Please select a child service' }))
    }

    const handleRemoveService = (evt) => {
        const filtered = selectedServices.filter((service, index) => index !== parseInt(evt.target.id))
        setSelectedServices(filtered)
        setServiceRequest({ ...serviceRequest, services: filtered })
    }
    return (
        <>
            <div className="form-group row">
                <div className="form-group col-6 mb-3">
                    <label className="">Clinic</label>
                    <select
                        defaultValue={'DEFAULT'}
                        className="form-select"
                        name="clinic"
                        onChange={(evt) => {
                            setServiceRequest({ ...serviceRequest, clinicId: evt.target.value })
                            dispatch($getServicesData(evt.target.value, location.companyId))
                        }}
                    >
                        <option value="DEFAULT">Please select a clinic</option>
                        {clinics &&
                            clinics?.map((clinic) => {
                                const { clinicId, clinicName } = clinic
                                return (
                                    <option key={clinicId} value={clinicId}>
                                        {clinicName}
                                    </option>
                                )
                            })}
                    </select>
                </div>
                <div className="form-group col-6 mb-3">
                    <label className="">Visit Reason</label>
                    <select
                        defaultValue={'DEFAULT'}
                        className="form-select"
                        name="reason"
                        onChange={(evt) => {
                            setServiceRequest({ ...serviceRequest, testReasonCode: evt.target.value })
                        }}
                    >
                        <option value="DEFAULT">Please select a reason</option>
                        {reasons &&
                            reasons?.map((reason) => {
                                const { testReasonId, testReasonName, testReasonCode } = reason
                                return (
                                    <option key={testReasonId} value={testReasonCode}>
                                        {testReasonName}
                                    </option>
                                )
                            })}
                    </select>
                </div>
            </div>

            <div className="form-group row mb-3">
                <div className="col">
                    <label htmlFor="comment" className="">
                        Notes to Clinic (optional)
                    </label>
                    <textarea id="comment" className="form-control" value={serviceRequest.comment} onChange={(evt) => setServiceRequest({ ...serviceRequest, comment: evt.target.value })} />
                </div>
            </div>

            {serviceRequest.clinicId && serviceRequest.testReasonCode && !!services.length && (
                <>
                    <div className="row mb-2">
                        <h4 className="text-center">Please select and add the requested services.</h4>
                    </div>
                    <div className="row">
                        <hr className="border-1 border-top border-dark mb-2" />
                        <div className="row ms-0 mb-2">
                            <div className="row col-5 me-2">
                                <select
                                    onChange={(e) => {
                                        setCategorySelectValue(e.target.value)
                                        setServiceSelectValue('')
                                    }}
                                    id="primary-services"
                                    defaultValue={'Primary Services'}
                                    placeholder="Service Category"
                                    className="form-select"
                                    name="primary-services"
                                >
                                    <option value="Primary Services">Service Category</option>
                                    {categories &&
                                        categories?.map((service) => {
                                            const { testTypeId, testTypeName } = service
                                            return (
                                                <option id={testTypeId} key={testTypeId} value={testTypeId}>
                                                    {testTypeName}
                                                </option>
                                            )
                                        })}
                                </select>
                            </div>
                            <div className="row col-5 me-2">
                                <select
                                    id="secondary-services"
                                    defaultValue={''}
                                    className={`form-select ${!!services?.filter((service) => parseInt(categorySelectValue) === service.parentTestTypeId).length ? '' : 'd-none'}`}
                                    name="secondary-services"
                                    onChange={(evt) => setServiceSelectValue(evt.target.value)}
                                    placeholder="Service"
                                >
                                    <option value="">Services</option>
                                    {services &&
                                        services?.map((service) => {
                                            const { testTypeId, testTypeName, parentTestTypeId } = service
                                            if (parseInt(categorySelectValue) === parentTestTypeId) {
                                                return (
                                                    <option id={testTypeId} key={testTypeId} value={testTypeId}>
                                                        {testTypeName}
                                                    </option>
                                                )
                                            } else {
                                                return null
                                            }
                                        })}
                                </select>
                            </div>
                            <div className="row col-2">
                                <Button
                                    title="Add Service"
                                    className="btn btn-info"
                                    cb={() => {
                                        handleAddService()
                                    }}
                                />
                            </div>
                        </div>
                        <hr className="border-1 border-top border-dark mb-2" />
                        <div className="table-responsive mt-2">
                            <table className="table table-centered table-nowrap mb-4">
                                <thead className="table-light">
                                    <tr>
                                        <th className="border-0">Service Name</th>
                                        <th className="border-0">Service Description</th>
                                        <th className="border-0"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedServices &&
                                        selectedServices?.map((service, idx) => {
                                            const { testTypeDescription, testTypeName } = service
                                            return (
                                                <tr key={idx}>
                                                    <td>{testTypeName}</td>
                                                    <td>{testTypeDescription}</td>

                                                    <td>
                                                        <span onClick={(evt) => handleRemoveService(evt)}>
                                                            <ion-icon name="trash-outline" id={idx} />
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    {!!selectedServices.length || (
                                        <tr>
                                            <td colSpan={3}>No Services Selected</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default TabServices

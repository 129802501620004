import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { store } from '../../redux/store'
import { enums } from '@primed/util-client'

// Redux
import {
    $getServiceRequests,
    selectIsServiceRequestsLoading,
    selectServiceRequestCount,
    selectServiceRequests,
    selectServiceRequestTableSearch,
    setPaginationDefaults,
    setServiceRequestsLoading,
    setServiceRequestTableSearch,
} from '../../redux/slices/dashboardSlice'
import { setAppError } from '../../redux/slices/appSlice'
import { selectOptions, selectSelectedLocation } from '../../redux/slices/employerSlice'

// Components
import DataTable from 'react-data-table-component'
import downloadCSV from '@primed/util/src/csv'
import { Button, Spinner } from '@primed/hyper'
import PageTitle from '../../components/app/PageTitle'

// Styles
import './Dashboard.scss'

function Dashboard() {
    const dispatch = useDispatch()
    const { hash } = useLocation()
    const serviceRequests = useSelector(selectServiceRequests)
    const totalRows = useSelector(selectServiceRequestCount)
    const [rowsPerPage, setRowsPerPage] = useState(store.getState().dashboard?.paginationPerPage || 25)
    const [pageNumber, setPageNumber] = useState(store.getState().dashboard?.paginationDefaultPage || 1)
    const [resetPage, setResetPage] = useState(false)
    const loading = useSelector(selectIsServiceRequestsLoading)
    const search = useSelector(selectServiceRequestTableSearch)
    const options = useSelector(selectOptions)
    const selectedLocation = useSelector(selectSelectedLocation)
    let currentSelectedLocation = useRef(selectedLocation)
    const textSearch = useRef()
    const startDateSearch = useRef()
    const endDateSearch = useRef()

    useEffect(() => {
        if (hash.includes('refresh') || !serviceRequests.length || selectedLocation.companyId !== currentSelectedLocation.current.companyId) {
            dispatch(setServiceRequestsLoading(true))
            dispatch(
                $getServiceRequests({
                    skip: 0 * rowsPerPage,
                    limit: rowsPerPage,
                    ...search,
                })
            )
            if (endDateSearch.current) {
                endDateSearch.current.value = search?.endDate || ''
            }
            if (startDateSearch.current) {
                startDateSearch.current.value = search?.startDate || ''
            }
            if (textSearch.current) {
                textSearch.current.value = search?.search || ''
            }
            currentSelectedLocation.current = selectedLocation.companyId
        }
    }, [selectedLocation, hash])

    useEffect(() => {
        if (endDateSearch.current) {
            endDateSearch.current.value = search?.endDate || ''
        }
        if (startDateSearch.current) {
            startDateSearch.current.value = search?.startDate || ''
        }
        if (textSearch.current) {
            textSearch.current.value = search?.search || ''
        }
    }, [startDateSearch, endDateSearch, textSearch])

    const FilterReason = () => (
        <select
            className="form-select p-1"
            onChange={(e) => {
                handleSearch({ reasonId: e.target.value ? parseInt(e.target.value) : '' })
            }}
            value={search?.reasonId}
        >
            <option value="">Test Reason</option>
            {options?.reasons?.map((test, idx) => (
                <option key={idx} value={test.testReasonId}>
                    {test.testReasonName}
                </option>
            ))}
        </select>
    )

    const FilterStatus = () => (
        <select
            className="form-select p-1"
            onChange={(e) => {
                handleSearch({ statusId: e.target.value ? parseInt(e.target.value) : '' })
            }}
            value={search?.statusId}
        >
            <option value="">Status</option>
            {options?.statuses?.map((status, idx) => (
                <option key={idx} value={status.authorizationPatientStatusID}>
                    {status.authorizationPatientStatusName}
                </option>
            ))}
        </select>
    )

    const FilterProject = () => (
        <select
            className="form-select p-1"
            onChange={(e) => {
                handleSearch({ projectId: e.target.value })
            }}
            value={search?.projectId}
        >
            <option value="">Project</option>
            {options?.projects?.map((project, idx) => {
                const { projectName, projectNumber, projectId } = project
                if (projectName === '' && projectNumber === '') {
                    return null
                }
                return (
                    <option key={idx} value={projectId}>
                        {`${projectName} (#${projectNumber})`}
                    </option>
                )
            })}
        </select>
    )

    const FilterJob = () => (
        <select
            className="form-select p-1"
            onChange={(e) => {
                handleSearch({ jobNumber: e.target.value })
            }}
            value={search?.jobNumber}
        >
            <option value="">Job Number</option>
            {options?.jobs?.map((job, idx) => {
                const { jobNumber } = job
                if (jobNumber === '') {
                    return null
                }
                return (
                    <option key={idx} value={jobNumber}>
                        {jobNumber}
                    </option>
                )
            })}
        </select>
    )

    function clearFilters() {
        textSearch.current.value = ''
        startDateSearch.current.value = ''
        endDateSearch.current.value = ''
        handleSearch({
            reasonId: '',
            jobNumber: '',
            projectId: '',
            statusId: '',
        })
    }

    const columns = [
        {
            name: 'Employee',
            selector: (row) => `${row.firstName} ${row.lastName}`,
        },
        {
            name: <FilterReason />,
            selector: (row) => row.testReasonName,
        },
        {
            name: <FilterStatus />,
            selector: (row) => row.status,
            cell: (row) => {
                return (
                    <>
                        <p className={`dashboard-status ${row.status} mt-2 ps-2`}>
                            {row.status}
                            <br />
                            <span className="small">{row.StatusDate?.split('T')[0]}</span>
                        </p>
                    </>
                )
            },
        },
        {
            name: 'Date of Visit',
            selector: (row) => row.dateOfVisit?.split('T')[0],
        },
        {
            name: <FilterProject />,
            selector: (row) => `${row.projectName} (#${row.projectNumber})`,
        },
        {
            name: <FilterJob />,
            selector: (row) => row.jobNumber,
        },
    ]

    if (selectedLocation.role !== enums.PRIMED_EMPLOYER_USER_ROLE.READER) {
        columns.push({
            name: '',
            width: '100px',
            selector: (row) => {
                return (
                    <Link className="btn btn-primary btn--view" to={`/employer/employee-reports?id=${row.authorizationPatientId}`}>
                        View
                    </Link>
                )
            },
        })
    }

    // Pagination

    const handlePageChange = (page, refetch = true) => {
        setResetPage(false)
        dispatch(setServiceRequestsLoading(true))
        dispatch(setPaginationDefaults({ paginationDefaultPage: page }))
        if (refetch) {
            dispatch(
                $getServiceRequests({
                    skip: (page - 1) * rowsPerPage,
                    limit: rowsPerPage,
                    ...search,
                })
            )
        } else {
            dispatch(setServiceRequestsLoading(false))
        }
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        dispatch(setPaginationDefaults({ paginationPerPage: newPerPage }))
        if (newPerPage > 500) {
            dispatch(setAppError({ error: 'Too many rows selected.  Please add more filters.' }))
            return
        }
        dispatch(setServiceRequestsLoading(true))
        setRowsPerPage(newPerPage)
        dispatch(
            $getServiceRequests({
                skip: (page - 1) * newPerPage,
                limit: newPerPage,
                ...search,
            })
        )
    }

    // CSV

    // Filters

    const handleSearch = (filter) => {
        dispatch(setServiceRequestsLoading(true))
        const newSearch = {
            ...search,
            ...filter,
            search: textSearch.current.value,
            startDate: startDateSearch.current.value,
            endDate: endDateSearch.current.value,
        }
        dispatch(setServiceRequestTableSearch(newSearch))
        setPageNumber(1)
        dispatch(setPaginationDefaults({ paginationPerPage: rowsPerPage }))
        setResetPage(true)
        dispatch(
            $getServiceRequests(
                {
                    skip: 0,
                    limit: rowsPerPage,
                    ...newSearch,
                },
                () => {
                    handlePageChange(1, false)
                }
            )
        )
    }

    const renderTableFilters = () => {
        return (
            <>
                <div className="input-group w-50 pe-2">
                    <input
                        type="text"
                        className="form-control"
                        ref={textSearch}
                        onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                                handleSearch()
                            }
                        }}
                    />
                    <button
                        className="btn btn-outline-dark"
                        onClick={(e) => {
                            handleSearch()
                        }}
                    >
                        Search
                    </button>
                </div>
                <div className="w-50 ps-5">
                    <div className="input-group">
                        <input className="form-control" type="date" ref={startDateSearch} />
                        <input className="form-control" type="date" ref={endDateSearch} />
                        <button
                            className="btn btn-outline-dark"
                            onClick={(e) => {
                                handleSearch()
                            }}
                        >
                            Filter By Date
                        </button>
                    </div>
                    <span className="small dashboard-start">Last Status Date Change - Defaults to last 30 days</span>
                </div>
                <hr className="mt-4 mb-2 w-100" style={{ border: '1px solid #bbb' }} />
            </>
        )
    }

    return (
        <>
            <PageTitle taskTitle={'Results Dashboard'}>
                <Button onClick={clearFilters} classes={['btn-small', 'btn-outline', 'dashboard-csv-btn', 'me-1']}>
                    <ion-icon name="funnel" />
                    <span className="ms-1">Clear Filters</span>
                </Button>
                <Button
                    onClick={() => {
                        downloadCSV(serviceRequests)
                    }}
                    classes={['btn-small', 'btn-primary', 'dashboard-csv-btn', 'me-1']}
                >
                    <ion-icon name="download" />
                    <span className="ms-1">Export Current Page</span>
                </Button>
            </PageTitle>

            <div className="row">
                <div className="col">
                    <div className="card d-block position-relative">
                        <div className="card-body pt-3">
                            <DataTable
                                columns={columns}
                                data={serviceRequests}
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handlePerRowsChange}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                paginationComponentOptions={{
                                    selectAllRowsItem: totalRows < 1000,
                                    selectAllRowsItemText: 'Show All',
                                }}
                                progressComponent={<Spinner classes={['mt-3']} />}
                                progressPending={loading}
                                subHeader
                                subHeaderComponent={renderTableFilters()}
                                title={`Service Requests for ${selectedLocation?.companyName} ${selectedLocation.isRootCompany ? '& All Associated Regions' : ''}`}
                                persistTableHead={true}
                                paginationDefaultPage={pageNumber}
                                paginationPerPage={rowsPerPage}
                                paginationRowsPerPageOptions={[25, 50, 100, 250, 500]}
                                paginationResetDefaultPage={resetPage}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard

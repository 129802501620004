import React from 'react'

function ProfileCompanyProfile({ location, companyInfo }) {
    return (
        <>
            <div className="card-body bg-primary p-3">
                <div className="row">
                    <div className="col-sm-8">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <div className="avatar-lg">
                                    <img src={`https://ui-avatars.com/api/?name=${location.companyName}&length=1`} alt="company" height="90" className="rounded-circle shadow-sm" />
                                </div>
                            </div>
                            <div className="col">
                                <div>
                                    <h4 className="mt-1 mb-1 text-white">{location?.companyName}</h4>
                                    <p className="font-13 text-white-50">Location Name</p>

                                    <ul className="mb-0 list-inline text-light">
                                        <li className="list-inline-item me-3">
                                            <h5 className="mb-1 text-white">{companyInfo?.companyId}</h5>
                                            <p className="mb-0 font-13 text-white-50">Company ID</p>
                                        </li>
                                        <li className="list-inline-item me-3">
                                            <h5 className="mb-1 text-white">{companyInfo?.taxId}</h5>
                                            <p className="mb-0 font-13 text-white-50">Tax ID</p>
                                        </li>
                                        <li className="list-inline-item me-3">
                                            <h5 className="mb-1 text-white">{companyInfo?.address1}</h5>
                                            <p className="mb-0 font-13 text-white-50">Address</p>
                                        </li>
                                        <li className="list-inline-item me-3">
                                            <h5 className="mb-1 text-white">{companyInfo?.addressCity}</h5>
                                            <p className="mb-0 font-13 text-white-50">City</p>
                                        </li>
                                        <li className="list-inline-item me-3">
                                            <h5 className="mb-1 text-white">{companyInfo?.addressState}</h5>
                                            <p className="mb-0 font-13 text-white-50">State</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileCompanyProfile

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { apiGet } from '../../../../utils/api'
import { Link, useNavigate } from 'react-router-dom'

// Redux
import { selectRole } from '../../../../redux/slices/userSlice'
import { setAppError } from '../../../../redux/slices/appSlice'
import { selectSelectedLocation } from '../../../../redux/slices/employerSlice'

// Components
import DataTable from 'react-data-table-component'
import { Button, Spinner } from '@primed/hyper'
import PageTitle from '../../../../components/app/PageTitle'

// Styles
function UserList() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { companyId } = useSelector(selectSelectedLocation)
    const userRole = useSelector(selectRole)

    const [allUsers, setAllUsers] = useState([])
    const [filteredUsers, setFilteredUsers] = useState([])

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (userRole === 'reader') {
            dispatch(setAppError({ error: 'You do not have access to view all employees.' }))
            navigate('/employer')
        }
        async function init() {
            setLoading(true)

            let { users } = await apiGet(`/protected/employer/settings/users/list?employerId=${companyId}`)

            // TODO: make this smarter, or even better - stop adding jobnum to all employers; NOTE: even if jobnum showed, our current implementation for managing locations breaks for him because his location tree is too big
            users = users.filter((user) => !user.userName.includes('jobnum@primeoccmed'))

            setAllUsers(users)
            setFilteredUsers(users)

            setLoading(false)
        }

        if (!allUsers.length) {
            void init()
        }
    }, [allUsers, companyId])

    const columns = [
        {
            name: 'Username',
            selector: (row) => row.userName,
            cell: (row) => row.userName,
        },
        {
            name: 'Name',
            selector: (row) => `${row.firstName} ${row.lastName}`,
        },
        {
            name: 'Active',
            selector: (row) => row.active,
            cell: (row) => (
                <div className="d-flex align-items-center">
                    <ion-icon name={row.active ? 'checkmark' : 'close'}></ion-icon>
                    <span className="ps-1">{row.active ? 'Active' : 'Inactive'}</span>
                </div>
            ),
        },
        {
            name: 'Locked',
            selector: (row) => row.locked,
            cell: (row) => (
                <div>
                    <ion-icon name={row.locked ? 'lock-closed' : 'lock-open'}></ion-icon>
                    <span className="ps-1">{row.locked ? 'Locked' : 'Unlocked'}</span>
                </div>
            ),
        },
        {
            name: '',
            selector: (row) => row.userName,
            cell: (row) => (
                <Link className="btn btn-sm btn-outline-primary" to={`./${row.userAccountId}`}>
                    View
                </Link>
            ),
        },
    ]
    const toInviteUser = () => {
        navigate('./invite-user')
    }

    const UserTitle = () => {
        return (
            <div className="d-flex justify-content-between">
                <h3 className="">Users</h3>
                <Button className="btn-primary" title="Invite New Users" cb={toInviteUser}></Button>
            </div>
        )
    }

    return (
        <>
            <PageTitle taskTitle={'Users'} />
            <div className="row">
                <div className="col container card">
                    <div className="card-body">
                        <DataTable
                            columns={columns}
                            data={filteredUsers}
                            pagination
                            paginationComponentOptions={{
                                selectAllRowsItem: true,
                                selectAllRowsItemText: 'Show All',
                            }}
                            progressComponent={<Spinner classes={['mt-3']} />}
                            progressPending={loading}
                            subHeader
                            title={<UserTitle />}
                            persistTableHead={true}
                            paginationPerPage={25}
                            paginationRowsPerPageOptions={[25, 50, 100, 250, 500]}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserList

import React, { useState } from 'react'
import { apiPut } from '../../utils/api'
import { useDispatch, useSelector } from 'react-redux'

// Redux
import { selectEmail } from '../../redux/slices/userSlice'
import { setAppError } from '../../redux/slices/appSlice'

// Components
import { Button, Spinner } from '@primed/hyper'

// Styles

export default function ProfileAccount({ account, userAccountId, setUserAccount }) {
    const loggedInEmail = useSelector(selectEmail)
    const dispatch = useDispatch()

    const [active, setActive] = useState(account.active || false)
    const [lastLoggedIn] = useState(account.lastLoggedIn || false)
    const [locked, setLocked] = useState(account.locked || false)
    const [passwordLastUpdated] = useState(account.passwordLastUpdated || '')
    const [userName] = useState(account.userName || '')
    const [status, setStatus] = useState('read')

    async function onDisable() {
        try {
            if (confirm(`Disable user account "${userName}"?`)) {
                const payload = { userAccountId }
                setStatus('load')
                await apiPut('protected/employer/settings/users/auth/disable', payload)
                setActive(false)
                setLocked(true)
                await setUserAccount({
                    ...account,
                    active: false,
                    locked: true,
                })
                setStatus('read')
            }
        } catch (e) {
            console.error(e)
            setStatus('read')
            dispatch(setAppError({ error: e }))
        }
    }

    async function onEnable() {
        try {
            if (confirm(`Enable user account "${userName}"?`)) {
                const payload = { userAccountId }
                setStatus('load')
                await apiPut('protected/employer/settings/users/auth/enable', payload)
                setActive(true)
                setLocked(false)
                await setUserAccount({
                    ...account,
                    active: true,
                    locked: false,
                })
                setStatus('read')
            }
        } catch (e) {
            console.error(e)
            dispatch(setAppError({ error: e }))
            setStatus('read')
        }
    }

    async function onReset() {
        try {
            if (confirm(`Reset password for user account "${userName}"?`)) {
                const payload = { userAccountId }
                setStatus('load')
                const response = await apiPut('protected/employer/settings/users/auth/password-reset', payload)
                if (response.error) {
                    dispatch(setAppError({ error: response.error }))
                } else {
                    alert(`An email has been sent to ${userName} for password reset`)
                }
                setStatus('read')
            }
        } catch (e) {
            console.error(e)
            dispatch(setAppError({ error: e }))
            setStatus('read')
        }
    }

    const printLastLoggedIn = () => (lastLoggedIn ? new Date(lastLoggedIn).toLocaleString() : 'N/A')

    const printPasswordLastUpdated = () => new Date(passwordLastUpdated).toLocaleString()

    return (
        <>
            <div className="col container card">
                <div className="card-header">
                    <div className="d-flex justify-content-between">
                        <h5>Security</h5>

                        {status === 'read' && loggedInEmail !== userName && (
                            <div>
                                {active && <Button title="Reset Password" cb={onReset} />}
                                {active ? <Button title="Disable" cb={onDisable} /> : <Button title="Enable" cb={onEnable} />}
                            </div>
                        )}
                        {status === 'load' && (
                            <div className="d-flex align-items-center">
                                <Spinner />
                                <span className="ps-1">Saving...</span>
                            </div>
                        )}
                    </div>
                </div>

                <div className="card-body">
                    <form id="userAddressForm">
                        <fieldset disabled>
                            <div className="mb-3">
                                <label htmlFor="userName" className="form-label">
                                    User Name
                                </label>
                                <input type="text" readOnly className="form-control-plaintext" id="userName" value={userName} />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="userName" className="form-label">
                                    Password (last updated at {printPasswordLastUpdated()})
                                </label>
                                <input type="text" readOnly className="form-control-plaintext" id="userName" value="********" />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="active" className="form-label">
                                    Active (last login at {printLastLoggedIn()})
                                </label>
                                <input type="text" readOnly className="form-control-plaintext" id="active" value={active ? 'Active' : 'Inactive'} />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="locked" className="form-label">
                                    Locked
                                </label>
                                <input type="text" readOnly className="form-control-plaintext" id="locked" value={locked ? 'Locked' : 'Unlocked'} />
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </>
    )
}

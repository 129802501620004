import { createSlice } from '@reduxjs/toolkit'
import { apiGet } from '../../utils/api'

const initialState = {
    clinics: [],
    locations: [],
    reasons: [],
    regions: [],
    categories: [],
    services: [],
    projects: [],
    purchaseOrders: [],
    jobs: [],
}

export const serviceRequestSlice = createSlice({
    name: 'service-request',
    initialState,
    reducers: {
        setClinics: (state, action) => {
            state.clinics = action.payload?.sort()
        },
        setLocations: (state, action) => {
            state.locations = action.payload?.sort()
        },
        setReasons: (state, action) => {
            state.reasons = action.payload?.sort()
        },
        setRegions: (state, action) => {
            state.regions = action.payload?.sort()
        },
        setCategories: (state, action) => {
            state.categories = action.payload?.sort((a, b) => {
                if (a.testTypeName < b.testTypeName) {
                    return -1
                }
                if (a.testTypeName > b.testTypeName) {
                    return 1
                }
                return 0
            })
        },
        setServices: (state, action) => {
            state.services = action.payload?.sort((a, b) => {
                if (a.testTypeName < b.testTypeName) {
                    return -1
                }
                if (a.testTypeName > b.testTypeName) {
                    return 1
                }
                return 0
            })
        },
        setProjects: (state, action) => {
            state.projects = action.payload?.sort()
        },
        setPurchaseOrders: (state, action) => {
            state.purchaseOrders = action.payload?.sort()
        },
        setJobs: (state, action) => {
            state.jobs = action.payload?.sort()
        },
    },
})

export const { setClinics, setLocations, setReasons, setRegions, setServices, setProjects, setPurchaseOrders, setJobs, setCategories } = serviceRequestSlice.actions

// Selectors
export const selectClinics = (state) => state.serviceRequest.clinics
export const selectJobs = (state) => state.serviceRequest.jobs
export const selectLocations = (state) => state.serviceRequest.locations
export const selectServices = (state) => state.serviceRequest.services
export const selectCategories = (state) => state.serviceRequest.categories
export const selectProjects = (state) => state.serviceRequest.projects
export const selectPurchaseOrders = (state) => state.serviceRequest.purchaseOrders
export const selectReasons = (state) => state.serviceRequest.reasons
export const selectRegions = (state) => state.serviceRequest.regions

// Thunks

export const $getLocationsData =
    (projectId = null) =>
    async (dispatch, getState) => {
        const locationOptions = await apiGet('protected/employer/service-request/options/location')
        const visitOptions = await apiGet('protected/employer/service-request/options/visit', { employerId: getState()?.employer?.selectedLocation?.companyId, projectId })
        dispatch(setClinics(locationOptions.clinics))
        dispatch(setJobs(visitOptions.jobs))
        dispatch(setLocations(locationOptions.locations))
        dispatch(setProjects(visitOptions.projects))
        dispatch(setPurchaseOrders(visitOptions.purchaseOrders))
        dispatch(setReasons(visitOptions.reasons))
        dispatch(setRegions(visitOptions.regions))
    }

export const $getServicesData = (clinicId, employerId) => async (dispatch, getState) => {
    const services = await apiGet('protected/employer/service-request/options/test', {
        clinicId,
        employerId,
    })
    dispatch(setServices(services.services))
    dispatch(setCategories(services.categories))
}

export default serviceRequestSlice.reducer

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'
import { apiGet, apiPut } from '../../../utils/api'

// Redux
import { selectSelectedLocation } from '../../../redux/slices/employerSlice'
import { setAppError } from '../../../redux/slices/appSlice'

// Components
import PageTitle from '../../../components/app/PageTitle'
import { Spinner } from '@primed/hyper'

// Styles
import './EmployeeReports.scss'
import { selectToken } from '../../../redux/slices/userSlice'

function EmployeeReports() {
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()
    const id = searchParams.get('id')
    const selectedLocation = useSelector(selectSelectedLocation)
    const token = useSelector(selectToken)
    const [visit, setVisit] = useState({})
    const [servicesRequested, setServicesRequested] = useState([])
    const [comps, setComps] = useState([])
    const [review, setReview] = useState({})
    const [poLoading, setPoLoading] = useState(false)

    let visitStatusColor = 'btn-light'
    let progress = 0
    switch (visit?.clinicStatusName?.toLowerCase()) {
        case 'cleared':
            progress = 100
            visitStatusColor = 'btn-success'
            break
        case 'not cleared':
            progress = 100
            visitStatusColor = 'btn-danger'
            break
        case 'pending tests':
            progress = 0
            visitStatusColor = 'btn-light'
            break
        default:
            progress = 66
            visitStatusColor = 'btn-warning'
            break
    }

    useEffect(() => {
        try {
            apiGet('protected/employer/service-request/services', { id, employerId: selectedLocation.companyId }).then(({ servicesRequested }) => {
                setServicesRequested(servicesRequested)
                apiGet('protected/employer/service-request/visit', { id, employerId: selectedLocation.companyId }).then((json) => {
                    if (json?.visit?.length) {
                        const visit = json.visit[0]
                        setVisit(visit)
                        setComps(json.compositions || [])
                        setReview(json.review)
                    }
                })
            })
        } catch (error) {
            dispatch(setAppError({ error: 'Could not load service information.' }))
        }
    }, [])

    async function editPO() {
        const purchaseOrder = await prompt(`Please enter a new purchase order number for this service, or cancel.`, visit?.purchaseOrder ?? '')
        if (visit?.purchaseOrder === purchaseOrder || purchaseOrder === '' || purchaseOrder == null) {
            return
        }

        try {
            setPoLoading(true)
            const result = await apiPut('protected/employer/service-request/purchase-order', { id, employerId: selectedLocation.companyId, purchaseOrder })
            if (result.error) {
                console.error(result.error)
                dispatch(setAppError({ error: 'Failed to update PO. Please try again.' }))
                return
            }

            setVisit({ ...visit, purchaseOrder })
        } catch (e) {
            console.error('Failed to update PO', e)
            dispatch(setAppError({ error: 'Failed to update PO. Please try again.' }))
        } finally {
            setPoLoading(false)
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-2 mt-3">
                    <Link to="/employer" className="btn btn-primary btn-small">
                        {'<'} Back to Dashboard{' '}
                    </Link>
                </div>
            </div>
            <PageTitle taskTitle={`Employee Report ${visit?.employeeFirstName ? `for ${visit?.employeeFirstName} ${visit?.employeeLastName}` : ``} ${id ? ` - ${id}` : ''}`} />

            <div className="row">
                <div className="col-12 text-center">
                    <button type="button" className={`btn mb-3 ${visitStatusColor}`}>
                        {visit?.clinicStatusName}
                    </button>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-lg-7 col-md-10 col-sm-11">
                    <div className="horizontal-steps mt-2 mb-4 pb-5" id="tooltip-container">
                        <div className="horizontal-steps-content">
                            <div className={`step-item ${progress === 0 ? 'current' : ''}`}>
                                <span data-bs-container="#tooltip-container" data-bs-toggle="tooltip" data-bs-placement="bottom" title="20/08/2018 07:24 PM">
                                    Employer Request
                                </span>
                            </div>
                            <div className="step-item">
                                <span data-bs-container="#tooltip-container" data-bs-toggle="tooltip" data-bs-placement="bottom" title="21/08/2018 11:32 AM">
                                    Checked Out
                                </span>
                            </div>
                            <div className={`step-item ${progress === 66 ? 'current' : ''}`}>
                                <span>Awaiting Results</span>
                            </div>
                            <div className={`step-item ${progress === 100 ? 'current' : ''}`}>
                                <span>Results Ready</span>
                            </div>
                        </div>

                        <div className="process-line" style={{ width: `${progress}%` }}></div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-8">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="header-title mb-2">Reason For Visit</h4>
                            <p className="text-muted">{visit?.testReasonName}</p>
                            <h4 className="header-title mb-2 mt-3">Notes</h4>

                            <p className="text-muted mb-3">
                                This visit was requested by {visit?.employerName} at the {visit?.clinicName} location. The reviewer{' '}
                                {review?.comment ? `added the following notes: ${review.comment}` : 'added no notes.'}
                                {visit?.notes && (
                                    <>
                                        <br />
                                        <br />
                                        {visit?.notes}
                                    </>
                                )}
                            </p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="header-title mb-3">Services Requested</h4>

                            <div className="table-responsive">
                                <table className="table mb-0">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Services</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!servicesRequested && (
                                            <tr>
                                                <td className="align-items-center">
                                                    <Spinner></Spinner>
                                                </td>
                                            </tr>
                                        )}
                                        {servicesRequested?.map((ser, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td>{ser.description}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="header-title mb-3">Forms</h4>
                            <div className="table-responsive">
                                <table className="table mb-0" style={{ verticalAlign: 'middle' }}>
                                    <thead className="table-light">
                                        <tr>
                                            <th>Form</th>
                                            <th width="250"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p className="mb-0">
                                                    <span style={{ fontSize: '20px' }}>Authorization Form</span>
                                                    <br />
                                                    <small>General Forms</small>
                                                </p>
                                            </td>
                                            <td>
                                                <a
                                                    className="btn btn-sm btn-outline-success me-2 w-100"
                                                    href={`${process.env.REACT_APP_API_HOST}/api/v1/public/pdf/service-request?apid=${id}&employerId=${selectedLocation.companyId}&token=${token}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <ion-icon name="download"></ion-icon>
                                                    <span className="ms-1">Download</span>
                                                </a>
                                            </td>
                                        </tr>
                                        {comps.map((doc) => {
                                            return (
                                                <tr key={doc.composedDocumentId}>
                                                    <td>
                                                        <p className="mb-0">
                                                            <span style={{ fontSize: '20px' }}>{doc.documentName}</span>
                                                            <br />
                                                            <small>General Forms</small>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        {/* <Button title="Download" classes={['btn-sm', 'btn-secondary', 'd-flex', 'align-items-center']}>
                                                            <ion-icon name="download"></ion-icon>
                                                            <span className="ms-1">Download</span>
                                                        </Button> */}
                                                        <a className="btn btn-sm btn-outline-success me-2 w-100" href={doc.signedUrl} target="_blank" rel="noreferrer">
                                                            <ion-icon name="download"></ion-icon>
                                                            <span className="ms-1">Download</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="header-title mb-3">Employee Information</h4>
                            <ul className="list-unstyled mb-0">
                                <li>
                                    <p className="mb-2">
                                        <span className="fw-bold me-2">Name:</span> {visit?.employeeFirstName} {visit?.employeeLastName}
                                    </p>
                                    <p className="mb-2">
                                        <span className="fw-bold me-2">Date of Birth:</span> {visit?.employeeDOB?.split('T')?.[0]}
                                    </p>
                                    <p className="mb-2">
                                        <span className="fw-bold me-2">Social Security Number:</span> xxx-xx-{visit?.employeeSSN?.toString().substring(5, 10)}
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="header-title mb-3">Employer Information</h4>

                            <ul className="list-unstyled mb-0">
                                <li>
                                    <p className="mb-2">
                                        <span className="fw-bold me-2">Project Number:</span> {visit?.projectNumber}
                                    </p>
                                    <p className="mb-2">
                                        <span className="fw-bold me-2">Job Number:</span> {visit?.jobNumber}
                                    </p>
                                    <p className="mb-2 d-flex gap-2 align-items-center">
                                        <span>
                                            <span className="fw-bold me-2">PO:</span> {visit?.purchaseOrder}
                                        </span>
                                        {poLoading ? (
                                            <Spinner></Spinner>
                                        ) : (
                                            <button className="btn btn-sm btn-outline-primary" onClick={editPO}>
                                                <i>edit</i>
                                            </button>
                                        )}
                                    </p>
                                    <p className="mb-2">
                                        <span className="fw-bold me-2">Name:</span> {visit?.employerName}
                                    </p>
                                    <p className="mb-2">
                                        <span className="fw-bold me-2">Address:</span> {visit?.employerAddress1}
                                    </p>
                                    <p className="mb-2">
                                        <span className="fw-bold me-2">City / State:</span> {visit?.employerAddressCity} {visit?.employerAddressState}
                                    </p>
                                    <p className="mb-2">
                                        <span className="fw-bold me-2">Services requested by:</span> {visit?.authorizationCreatedBy}
                                    </p>
                                    <p className="mb-2">
                                        <span className="fw-bold me-2">Services requested at:</span> {new Date(visit?.authorizationCreatedAt).toLocaleString()}
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="header-title mb-3">Clinic Information</h4>

                            <h5>{visit?.clinicName}</h5>

                            <address className="mb-0 font-14 address-lg">
                                {visit?.clinicAddress1}
                                <br />
                                {`${visit?.clinicAddressCity || ''} ${visit?.clinicAddressState || ''} ${visit?.clinicAddressPostalCode || ''}`}
                                <br />
                                {!!visit?.clinicPhone && (
                                    <>
                                        <span title="Phone">Phone Number:</span> {visit?.clinicPhone || ''} <br />
                                    </>
                                )}
                                {!!visit?.clinicAfterHoursPhoneNumber && (
                                    <>
                                        <span title="AfterHours">After Hours Number:</span> {visit?.clinicAfterHoursPhoneNumber || ''} <br />
                                    </>
                                )}

                                {!!visit?.clinicFaxNumber && (
                                    <>
                                        <span title="Fax">Fax Number:</span> {visit?.clinicFaxNumber || ''} <br />
                                    </>
                                )}
                            </address>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-2 mt-3">
                    <Link to="/employer" className="btn btn-primary btn-small">
                        {'<'} Back to Dashboard{' '}
                    </Link>
                </div>
            </div>
        </>
    )
}

export default EmployeeReports

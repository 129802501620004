import React, { useState, useRef } from 'react'
import { enums } from '@primed/util-client'
import { useDispatch, useSelector } from 'react-redux'
import { apiPost } from '../../../../utils/api'
import { Link } from 'react-router-dom'

// Redux
import { selectSelectedLocation } from '../../../../redux/slices/employerSlice'
import { setAppError } from '../../../../redux/slices/appSlice'

// Components
import { Button, Spinner } from '@primed/hyper'
import PageTitle from '../../../../components/app/PageTitle'

// Styles

export default function UserInvite() {
    const dispatch = useDispatch()
    const { companyId } = useSelector(selectSelectedLocation)
    const [status, setStatus] = useState('edit')
    const [validation, setValidation] = useState('')
    const [complete, setComplete] = useState(false)
    const user = useRef({})

    const onChange = (event) => {
        const { name, value } = event.target
        user.current = { ...user.current, [name]: value }
    }

    const inviteUser = async (event) => {
        try {
            event.preventDefault()
            setStatus('loading')
            const payload = {
                employerId: companyId,
                userInfo: user.current,
            }
            const response = await apiPost('protected/employer/settings/users/auth/invite', payload)
            if (response.error) {
                if (response.validation) {
                    setValidation(response.validation)
                    dispatch(setAppError({ error: response.error }))
                } else {
                    dispatch(setAppError({ error: response.error }))
                }
            } else {
                setComplete(true)
                setValidation('')
            }
        } catch (error) {
            console.error(error)
        } finally {
            setStatus('edit')
        }
    }

    return (
        <>
            <PageTitle taskTitle={'Invite User'} />
            <form onSubmit={inviteUser}>
                <div className="row">
                    <div className="container card">
                        <div className="card-body">
                            {!complete && (
                                <>
                                    <div className="row">
                                        <h5 className="card-header mb-2">Create User Profile</h5>
                                        {!!validation && <p className="small text-danger mt-0 mb-2">Invalid Field: {validation}</p>}
                                        <div className="col-6">
                                            <div className="form-floating mb-3">
                                                <input type="email" id="userName" name="userName" className="form-control" placeholder="test@test.com" onChange={onChange} required="true" />
                                                <label htmlFor="userName" className="form-label">
                                                    Email / Username
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-floating mb-3">
                                                <input type="tel" id="phone" name="phone" className="form-control" placeholder="111-222-4444" onChange={onChange} required="true" />
                                                <label htmlFor="phone" className="form-label">
                                                    Phone
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-5">
                                            <div className="form-floating mb-3">
                                                <input type="text" id="firstName" name="firstName" className="form-control" placeholder="John" onChange={onChange} required="true" />
                                                <label htmlFor="firstName" className="form-label">
                                                    First Name
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="form-floating mb-3">
                                                <input type="text" id="middleName" name="middleName" className="form-control" placeholder="G" onChange={onChange} />
                                                <label htmlFor="middleName" className="form-label">
                                                    Initial
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <div className="form-floating mb-3">
                                                <input type="text" id="lastName" name="lastName" className="form-control" placeholder="Doe" onChange={onChange} required="true" />
                                                <label htmlFor="lastName" className="form-label">
                                                    Last Name
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="form-floating mb-3">
                                                <input type="text" id="suffix" name="suffix" className="form-control" placeholder="Jr" onChange={onChange} />
                                                <label htmlFor="suffix" className="form-label">
                                                    Suffix
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="form-floating mb-3">
                                                <select className="form-select" id="gender" name="gender" defaultValue="" onChange={onChange} required="true">
                                                    <option value="" disabled>
                                                        Select gender
                                                    </option>
                                                    {Object.entries(enums.LEGACY_SEX).map(([key, val]) => (
                                                        <option className="text-capitalize" key={key} value={val}>
                                                            {key}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label htmlFor="gender">Gender</label>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-floating mb-3">
                                                <input type="date" id="dob" name="dateOfBirth" className="form-control" placeholder="01/01/1990" onChange={onChange} required="true" />
                                                <label htmlFor="dob" className="form-label">
                                                    Date of birth
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-floating mb-3">
                                                <select className="form-select" id="role" name="role" defaultValue="" onChange={onChange} required="true">
                                                    <option value="" disabled>
                                                        Select Role
                                                    </option>
                                                    {Object.entries(enums.PRIMED_EMPLOYER_USER_ROLE).map(([key, val]) => (
                                                        <option className="text-capitalize" key={key} value={val}>
                                                            {key}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label htmlFor="relationType">Access Level</label>
                                            </div>
                                        </div>
                                        <h5 className="card-header mb-2">Address</h5>
                                        <div className="col-6">
                                            <div className="form-floating mb-3">
                                                <input type="text" id="address1" name="address1" className="form-control" placeholder="123 ABC Street" onChange={onChange} required="true" />
                                                <label htmlFor="address1" className="form-label">
                                                    Address Line 1
                                                </label>
                                            </div>

                                            <div className="form-floating mb-3">
                                                <input type="text" id="address2" name="address2" className="form-control" placeholder="Suite D" onChange={onChange} />
                                                <label htmlFor="address2" className="form-label">
                                                    Address Line 2
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-floating mb-3">
                                                <input type="text" id="city" name="city" className="form-control" placeholder="Baton Rouge" onChange={onChange} required="true" />
                                                <label htmlFor="city" className="form-label">
                                                    City
                                                </label>
                                            </div>
                                            <div className="row">
                                                <div className="col-3">
                                                    <div className="form-floating mb-3">
                                                        <select className="form-select" name="state" id="state" defaultValue="" onChange={onChange} required="true">
                                                            <option value="" disabled>
                                                                Select
                                                            </option>
                                                            {Object.entries(enums.UNITED_STATES).map(([key, val]) => (
                                                                <option key={key} value={val}>
                                                                    {key}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <label htmlFor="state">State</label>
                                                    </div>
                                                </div>
                                                <div className="col-9">
                                                    <div className="form-floating mb-3">
                                                        <input
                                                            type="text"
                                                            id="postalCode"
                                                            name="postalCode"
                                                            className="form-control"
                                                            placeholder="77777"
                                                            pattern="(\d+|\d+-\d+)"
                                                            onChange={onChange}
                                                            required
                                                        />
                                                        <label htmlFor="postalCode" className="form-label">
                                                            Zip Code
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            {status === 'edit' && <Button className="btn-primary float-end" title="Invite new user" type="submit"></Button>}
                                            {status === 'loading' && <Spinner className="float-end"></Spinner>}
                                        </div>
                                    </div>
                                </>
                            )}
                            {!!complete && (
                                <>
                                    <h4 className="text-center mb-3">
                                        <ion-icon name="checkmark-done-circle-outline" style={{ fontSize: '48px' }}></ion-icon>
                                        <br />
                                        User Invite Sent!
                                    </h4>
                                    <div className="text-center">
                                        <Link to="/employer" className="btn btn-success">
                                            Return To Dashboard
                                        </Link>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { apiPut } from '../../utils/api'

// Redux
import { setAppError } from '../../redux/slices/appSlice'
import { signInUser } from '../../redux/slices/userSlice'

// Components
import { Button, Spinner } from '@primed/hyper'

// Styles

export default function ProfileChangePassword() {
    const dispatch = useDispatch()
    const [previous, setPrevious] = useState('')
    const [proposed, setProposed] = useState('')
    const [status, setStatus] = useState('read')
    const [valid, setValid] = useState('')

    function onCancel() {
        setPrevious('')
        setProposed('')
        setStatus('read')
    }

    const onEdit = () => setStatus('edit')

    async function onSave(event) {
        event.preventDefault()

        try {
            setStatus('load')
            const passwords = {
                previous,
                proposed,
            }
            const response = await apiPut('protected/profile/change-password', passwords)

            if (response.error) {
                if (response.validation) {
                    setValid(response.validation)
                } else {
                    dispatch(setAppError(response))
                }
                setStatus('edit')
            } else {
                setValid('')
                dispatch(signInUser(response))
                setStatus('success')
            }
        } catch (e) {
            setStatus('edit')
        }
    }

    return (
        <>
            <form className="col container card" onSubmit={onSave}>
                <div className="card-header">
                    <div className="d-flex justify-content-between">
                        <h4>Change Password</h4>
                        <div>
                            {status === 'read' && <Button title="Change Password" cb={onEdit}></Button>}
                            {status === 'edit' && <Button title="Cancel" cb={onCancel}></Button>}
                            {status === 'edit' && <Button title="Save" classes={['btn-outline-success']} type="submit"></Button>}
                            {status === 'load' && (
                                <div className="d-flex align-items-center">
                                    <Spinner />
                                    <span className="ps-1">Saving...</span>
                                </div>
                            )}
                            {status === 'success' && (
                                <Button className="btn-success disabled">
                                    <ion-icon name="checkmark"></ion-icon>
                                    <span className="ms-1">Updated Password</span>
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {!!valid && <p className="small text-danger">Password does not meet requirements</p>}
                    <fieldset disabled={status === 'edit' ? undefined : 'disabled'}>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">
                                Current Password
                            </label>
                            <div className="input-group">
                                <input type="password" id="password" className="form-control" placeholder="Enter your password" value={previous} onChange={(e) => setPrevious(e.target.value)}></input>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">
                                New Password
                            </label>
                            <div className="input-group">
                                <input
                                    type="password"
                                    id="newPassword"
                                    className="form-control"
                                    placeholder="Enter your password"
                                    value={proposed}
                                    onChange={(e) => setProposed(e.target.value)}
                                ></input>
                            </div>
                            <span class="help-block">
                                <small>Requires minimum of 8 characters: one capital, one lowercase, one symbol, and one number.</small>
                            </span>
                        </div>
                    </fieldset>
                </div>
            </form>
        </>
    )
}

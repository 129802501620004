const { getNames, getAlpha2Codes, getAlpha3Codes, langs } = require('i18n-iso-countries')

const ADDRESS_TYPE = {
    POSTAL: 'postal',
    PHYSICAL: 'physical',
    BOTH: 'both',
}

const ADDRESS_USE = {
    HOME: 'home',
    WORK: 'work',
    TEMP: 'temp',
    OLD: 'old',
    BILLING: 'billing',
}

const ADJUSTABLE_DISPLAY = {
    APPEND: 'append',
    REPLACE: 'replace',
}

const ADJUSTABLE_RATE = {
    FLAT: 'flat',
    PERCENT: 'percent',
}

const ADJUSTABLE_TYPE = {
    CLIENT: 'client',
    CLINIC: 'clinic',
    INVOICE: 'invoice',
}

const CHARGE_ITEM_STATUS = {
    PLANNED: 'planned',
    BILLABLE: 'billable',
    NOT_BILLABLE: 'not-billable',
    ABORTED: 'aborted',
    BILLED: 'billed',
    ENTERED_IN_ERROR: 'entered-in-error',
    UNKNOWN: 'unknown',
}

const COMPANY_ROLE = {
    CLINIC: 'clinic',
    EMPLOYER: 'employer',
    ONSITE: 'onsite',
    PAYEE: 'payee',
    PAYOR: 'payor',
    REVIEWER: 'reviewer',
}

const COMPANY_USER_ACCOUNT_RELATION_TYPE = {
    MASTER: { id: 1, code: 'MASTER', name: 'Master User' },
    ADV: { id: 2, code: 'ADV', name: 'Advanced User' },
    BASIC: { id: 3, code: 'BASIC', name: 'Basic User' },
    CLINICIAN: { id: 4, code: 'CLINICIAN', name: 'Clinician' },
    REVIEWER: { id: 5, code: 'REVIEWER', name: 'Reviewer' },
    CASEMANAGE: { id: 6, code: 'CASEMANAGE', name: 'Case Manager' },
    REVDASH: { id: 7, code: 'REVDASH', name: 'Reviewer Dashboard' },
    PROVIDER: { id: 8, code: 'PROVIDER', name: 'Provider User' },
}

COMPANY_USER_ACCOUNT_RELATION_TYPE.mapToPrimedEmployerUserRole = function mapToPrimedEmployerUserRole(relationTypeId) {
    if (relationTypeId === this.MASTER.id) {
        return PRIMED_EMPLOYER_USER_ROLE.ADMIN
    }

    if (relationTypeId === this.ADV.id) {
        return PRIMED_EMPLOYER_USER_ROLE.WRITER
    }

    return PRIMED_EMPLOYER_USER_ROLE.READER
}

const DATABASE_NAMES = {
    ADMIN: 'admin',
    REVIEW: 'quickreview',
    DEFAULT: 'primed',
}

const CONTACT_ENTITY_TYPE = {
    BILL: 'Billing',
    ADMIN: 'Administrative',
    HR: 'Human Resource',
    PAYOR: 'Payor',
    PATINF: 'Patient',
    PRESS: 'Press',
}

const CONTACT_POINT_SYSTEM = {
    PHONE: 'phone',
    FAX: 'fax',
    EMAIL: 'email',
    PAGER: 'pager',
    URL: 'url',
    SMS: 'sms',
    OTHER: 'other',
}

const CONTACT_POINT_USE = {
    HOME: 'home',
    WORK: 'work',
    TEMP: 'temp',
    OLD: 'old',
    MOBILE: 'mobile',
}

const LANGUAGES = {
    EN: 'en',
    ...langs().reduce((seed, next) => {
        seed[next.toUpperCase()] = next
        return seed
    }, {}),
}

/// { ...[XX]: 'Xxxx xx Xxx' }
const COUNTRY_NAMES = {
    US: 'United States of America',
    ...getNames(LANGUAGES.EN),
}

/// { ...[XX]: 'XXX' }
const COUNTRY_ALPHA2_CODES = { US: 'USA', ...getAlpha2Codes() }

/// { ...[XXX]: 'XX' }
const COUNTRY_ALPHA3_CODES = { USA: 'US', ...getAlpha3Codes() }

const EMPLOYEE_ROLE = {
    CLINIC_PROVIDER: 'clinic_provider', // Administrates patient visits
    CLINIC_TECHNICIAN: 'clinic_technician', // Facilitate check-in and check-out
    EMPLOYER_MANAGER: 'employer_manager', // Gives authorizations to providers to perform services and requests those services from partnered clinics
    EMPLOYER_EMPLOYEE: 'employer_employee', // Visits clinics
    REVIEWER_MRO: 'reviewer_mro', // Reviews visits (MRO = Medical Review Officer)
}

const FORM_STATE = {
    REQUESTED: 'requested',
    VIEWED: 'viewed',
    STARTED: 'started',
    FILLED: 'filled',
    ACCEPTED: 'accepted',
    CHANGES_REQUESTED: 'changes_requested',
    REVIEWED: 'reviewed',
    SIGNED: 'signed',
}

const GENDER = {
    MALE: 'male',
    FEMALE: 'female',
    OTHER: 'other',
    UNKNOWN: 'unknown',
}

const GENERIC_FIELD_TYPE = {
    attachment: {
        label: 'Attachment',
        type: 'attachment',
    },
    checkbox: {
        label: 'Checkbox',
        type: 'checkbox',
    },
    date: {
        label: 'Date (without time)',
        type: 'date',
    },
    email: {
        label: 'Email',
        type: 'email',
    },
    ref: {
        label: 'Reference',
        type: 'ref',
    },
    text: {
        label: 'Text (inline)',
        type: 'text',
    },
    textarea: {
        label: 'Text (multiline)',
        type: 'textarea',
    },
}

const IMAGE_SIZE = {
    // 16:9 ratios (not exactly square, but most images will have probably have pretty high rez)
    ICON: 'icon', // 160x90 px
    SM: 'sm', // 640x360 px
    MD: 'md', // 1280x720 px
    LG: 'lg', // 1920x1080 px
    ORIG: 'orig', // Original picture size
}

const INVOICE_BILL_STATUS = {
    GENERATING: 'generating',
    QUEUE_EMAIL: 'queue-email',
    QUEUE_FAX: 'queue-fax',
    QUEUE_POSTAL: 'queue-postal',
    SENT: 'sent',
    ERROR_GENERATE: 'error-generate',
    ERROR_SEND: 'error-send',
}

const INVOICE_FREQUENCY = {
    MONTHLY: 'monthly',
    WEEKLY: 'weekly',
}

const INVOICE_GROUP_BY = {
    // Aside from these, invoices always group by payeeId and payorId
    CLIENT: 'client',
    CLIENT_REGION: 'client-region',
    JOB_NUMBER: 'job-number',
    PATIENT: 'patient',
    PURCHASE_ORDER: 'purchase-order',
    TEST_REASON: 'visit-reason',
}

const INVOICE_SEND_VIA = {
    EMAIL: 'email',
    FAX: 'fax',
    POSTAL: 'postal',
}

const INVOICE_STATE = {
    OPEN: 'Open',
    PENDING_SUBMISSION: 'PendingSubmission',
    PENDING_PAYMENT: 'PendingPayment',
    CLOSED: 'Closed',
}

const INVOICE_STATUS = {
    DRAFT: 'draft',
    ISSUED: 'issued',
    BALANCED: 'balanced',
    CANCELLED: 'cancelled',
    ENTERED_IN_ERROR: 'entered-in-error',
}

const LEGACY_SEX = {
    MALE: 'M',
    FEMALE: 'F',
}

const LEGACY_SECURITY_GROUP = {
    ADMIN: { id: 1, code: 'ADMIN', name: 'Prime Admin' },
    EMPLOYER: { id: 2, code: 'EMPLOY', name: 'Employer' },
    REVIEWER: { id: 3, code: 'REVIEWER', name: 'Reviewer' },
    CLINIC_MVC: { id: 4, code: 'CLINIC', name: 'Clinic User' },
    PROVIDER: { id: 5, code: 'PROVIDER', name: 'Provider User' },
    CLINIC_NG: { id: 6, code: 'BILLING', name: 'Billing' },
}

const NAME_USE = {
    USUAL: 'usual',
    OFFICIAL: 'official',
    TEMP: 'temp',
    NICKNAME: 'nickname',
    ANONYMOUS: 'anonymous',
    OLD: 'old',
    MAIDEN: 'maiden',
}

const PAYMENT_METHOD = {
    CASH: 'Cash',
    CCCA: 'Credit Card',
    CCHK: "Cashier's Check",
    CDAC: 'Credit/Debit Account',
    CHCK: 'Check',
    DDPO: 'Direct Deposit',
    DEBC: 'Debit Card',
    SWFT: 'Society for Worldwide Interbank Financial Telecommunications (S.W.I.F.T.)',
    TRAC: "Traveler's Check",
    VISN: 'VISA Special Electronic Funds Transfer Network',
}

const TRANSACTION_TYPE = {
    CHARGE: 'Charge', // TODO: IS IT OK TO USE CHARGE HERE? NEED TO DOUBLE CHECK THIS WITH BILLING. THERE MIGHT BE SPECIFIC NAMES FOR CLINICAL SERVICES WE PROVIDE.

    // PAYMENTS: These are credits.
    PSP: 'Payment Self Pay',
    PEMP: 'Payment Employer',
    PWC: 'Payment Work Comp',
    PCOMM: 'Payment Commercial',
    PTPA: 'Payment TPA',
    PSPCC: 'Payment Self Pay Credit Card',
    PEMPCC: 'Payment Employer Credit Card',
    PWCCC: 'Payment Work Comp Credit Card',
    PCOMMCC: 'Payment Commercial Credit Card',
    PTPACC: 'Payment TPA Credit Card',

    // PAYMENT REVERSALS: (These are debits.)
    PSTOPPAY: 'Payment Stop Payment',
    PNSF: 'Payment NSF',
    PSPCCCR: 'Payment Self Pay Credit Card',
    PEMPCCCR: 'Payment Employer Credit Card',
    PWCCCCR: 'Payment Work Comp Credit Card',
    PCOMMCCCR: 'Payment Commercial Credit Card',

    // ADJUSTMENTS: These are credits to an invoice unless they have a minus in front of them, then they are a reversal.
    AAWO: 'Administrative Adjustment Write-Off',
    MDNOCHGWO: 'MD No Charge Write-Off',
    BDWO: 'Bad Debt Write-Off',
    WORKCOMPCA: 'Work Comp Contractual Adj',
    COMMCA: 'Managed Care Contractual Adj',
    PROFDISC: 'Professional Discount',
    NTFWO: 'Not Timely Filed Write-Off',
    NTWWO: 'Not Timely Worked Write-Off',
    NOAUTHWO: 'No Auth Write-Off',
    BANKWO: 'Bankruptcy Write-Off',

    // ADJUSTMENT Credits/Reversals:  These are DEBITS to the invoice or transaction.
    AAWOCR: 'Administrative Adjustment Write-Off',
    MDNOCHGWOCR: 'MD No Charge Write-Off',
    BDWOCR: 'Bad Debt Write-Off',
    WORKCOMPCACR: 'Work Comp Contractual Adj',
    COMMCACR: 'Managed Care Contractual Adj',
    PROFDISCCR: 'Professional Discount',
    NTFWOCR: 'Not Timely Filed Write-Off',
    NTWWOCR: 'Not Timely Worked Write-Off',
    NOAUTHWOCR: 'No Auth Write-Off',
    BANKWOCR: 'Bankruptcy Write-Off',

    // REFUNDS: refunds debit the invoice unless they are a reversal then they need a minus in front of the amount
    REFSP: 'Refund Self-Pay',
    REFCOMM: 'Refund Commercial',
    REFEMP: 'Refund EMP',
    REFTPA: 'Refund TPA',
    REFWC: 'Refund WC',
    REFOTH: 'Refund OTH',

    // REFUND REVERSALS:  (These are credits to an invoice or transaction like a void of a refund.)
    REFSPCR: 'Refund Self-Pay',
    REFCOMMCR: 'Refund Commercial',
    REFEMPCR: 'Refund EMP',
    REFTPACR: 'Refund TPA',
    REFWCCR: 'Refund WC',
    REFOTHCR: 'Refund OTH',

    // TRANSFER: (transfer a payment from one invoice to another—this is a two-sided, offsetting transaction. )
    TXIN: 'Transfer From Invoice', // credit
    TXOUT: 'Transfer To Invoice', // debit
}

const TRANSACTION_CATEGORY = {
    CREDIT: {
        name: 'credit',
        types: [
            TRANSACTION_TYPE.PSP,
            TRANSACTION_TYPE.PEMP,
            TRANSACTION_TYPE.PWC,
            TRANSACTION_TYPE.PCOMM,
            TRANSACTION_TYPE.PTPA,
            TRANSACTION_TYPE.PSPCC,
            TRANSACTION_TYPE.PEMPCC,
            TRANSACTION_TYPE.PWCCC,
            TRANSACTION_TYPE.PCOMMCC,
            TRANSACTION_TYPE.PTPACC,
            TRANSACTION_TYPE.AAWO,
            TRANSACTION_TYPE.MDNOCHGWO,
            TRANSACTION_TYPE.BDWO,
            TRANSACTION_TYPE.WORKCOMPCA,
            TRANSACTION_TYPE.COMMCA,
            TRANSACTION_TYPE.PROFDISC,
            TRANSACTION_TYPE.NTFWO,
            TRANSACTION_TYPE.NTWWO,
            TRANSACTION_TYPE.NOAUTHWO,
            TRANSACTION_TYPE.BANKWO,
            TRANSACTION_TYPE.REFSPCR,
            TRANSACTION_TYPE.REFCOMMCR,
            TRANSACTION_TYPE.REFEMPCR,
            TRANSACTION_TYPE.REFTPACR,
            TRANSACTION_TYPE.REFWCCR,
            TRANSACTION_TYPE.REFOTHCR,
            TRANSACTION_TYPE.TXIN,
        ],
    },
    DEBIT: {
        name: 'debit',
        types: [
            TRANSACTION_TYPE.CHARGE, // TODO: THIS IS TBD
            TRANSACTION_TYPE.PSTOPPAY,
            TRANSACTION_TYPE.PNSF,
            TRANSACTION_TYPE.PSPCCCR,
            TRANSACTION_TYPE.PEMPCCCR,
            TRANSACTION_TYPE.PWCCCCR,
            TRANSACTION_TYPE.PCOMMCCCR,
            TRANSACTION_TYPE.AAWOCR,
            TRANSACTION_TYPE.MDNOCHGWOCR,
            TRANSACTION_TYPE.BDWOCR,
            TRANSACTION_TYPE.WORKCOMPCACR,
            TRANSACTION_TYPE.COMMCACR,
            TRANSACTION_TYPE.PROFDISCCR,
            TRANSACTION_TYPE.NTFWOCR,
            TRANSACTION_TYPE.NTWWOCR,
            TRANSACTION_TYPE.NOAUTHWOCR,
            TRANSACTION_TYPE.BANKWOCR,
            TRANSACTION_TYPE.REFSP,
            TRANSACTION_TYPE.REFCOMM,
            TRANSACTION_TYPE.REFEMP,
            TRANSACTION_TYPE.REFTPA,
            TRANSACTION_TYPE.REFWC,
            TRANSACTION_TYPE.REFOTH,
            TRANSACTION_TYPE.TXOUT,
        ],
    },
}

const PAYOR_TYPE = {
    EMPLOYER: 'employer',
    PATIENT: 'patient', // There should only be one payor with a patient payor type! These mark self-pays and the patients usually pay for their services at the time of the visit.
    THIRD_PARTY_ASSOCIATION: 'third party association',
    WORKERS_COMPENSATION: "workers' compensation",
}

const PRICE_COMPONENT_TYPE = {
    BASE: 'base',
    SURCHARGE: 'surcharge',
    DEDUCTION: 'deduction',
    DISCOUNT: 'discount',
    TAX: 'tax',
    INFORMATIONAL: 'informational',
}

const PRIMED_APP = {
    ALL: '/',
    ADMIN: '/apps/admin',
    API: '/apps/api',
    AUTH: '/apps/auth',
    FINANCE: '/apps/finance',
    PATIENT: '/apps/patient',
    EMPLOYER: '/apps/employer',
    APPS: '/apps',
    JOB_BULK_INVOICE: '/jobs/bulk_invoice',
    JOB_LEGACY_CHECKOUT_IMPORT: '/jobs/legacy_checkout_import',
    JOBS: '/jobs',
    WORKER_PRIME: '/workers/prime',
    WORKERS: '/workers',
}

const PRIMED_CONFIG_KEY = {
    PREFERRED_LOCATION_ID: '/preferences/locationId',
}

const PRIMED_EMPLOYER_USER_ROLE = {
    ADMIN: 'admin',
    READER: 'reader',
    WRITER: 'writer',
}

const PRIMED_REVIEWER_USER_ROLE = {
    CLINIC: { role: 0, title: 'Clinic' },
    CLINIC_ADMIN: { role: 1, title: 'Clinic Admin' },
    REVIEWER: { role: 2, title: 'Reviewer' },
    ADMIN: { role: 3, title: 'Admin' },
    DEVELOPER: { role: 4, title: 'Developer' },
}

const UNITED_STATES = {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'American Samoa',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District Of Columbia',
    FM: 'Federated States Of Micronesia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MH: 'Marshall Islands',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    MP: 'Northern Mariana Islands',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palau',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Virgin Islands',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
}

const USER_GROUP = {
    ADMIN: 'admin',
    BILLING: 'billing',
    CLINIC: 'clinic',
    EMPLOYER: 'Employer',
    PATIENT: 'patient',
    REVIEWER: 'reviewer',
}

const TEST_REASON = {
    DRGSCRN: 'Drug Screen',
    ANNUAL: 'Annual',
    VACCINE: 'Vaccination',
    PREEMP: 'Pre-Employment',
    FITDUTY: 'Fit for Duty',
    RANDOM: 'Random',
    RETRNWRK: 'Return to Work',
    RSNSUSPCN: 'Reasonable Suspicion',
    FOLLOWUP: 'Follow Up',
    PREACCESS: 'Pre-Access',
    INJURY: 'Injury',
    ILLNESS: 'Illness',
    QUEST: 'Questionnaire',
    PSTACCDNT: 'Post-Accident',
    EKG: 'EKG',
    LABONLY: 'Lab Testing Only',
    NONWCCARE: 'Non-WC Care',
}

const VISIT_STATE = {
    UNSCHEDULED: 'unscheduled',
    SCHEDULED: 'scheduled',
    CANCELLED: 'cancelled',
    CONFIRMED: 'confirmed',
    CHECKED_IN: 'checked_in',
    CHECKED_OUT: 'checked_out',
    INTERRUPTED: 'interrupted',
}

const WIDGET = {
    BOOLEAN: 'boolean',
    DATE: 'date',
    DATETIME: 'datetime',
    FILE: 'file',
    FLOAT: 'float',
    INTEGER: 'integer',
    LIST: 'list',
    MARKDOWN: 'markdown',
    MEDIA: 'media',
    MULTISELECT: 'multiselect',
    RADIO: 'radio',
    RICH_TEXT: 'rich_text',
    SECTION: 'section',
    SELECT: 'select',
    STRING: 'string',
    TEXT: 'text',
    TIME: 'time',
}

const parseGender = (gender) => {
    if (!gender) {
        return GENDER.UNKNOWN
    }

    const genderEnum = Object.values(GENDER)
    const parsedSex = genderEnum.find((enumGender) => enumGender.toLowerCase().startsWith(gender.toLowerCase()))
    return parsedSex || GENDER.UNKNOWN
}

module.exports = {
    ADDRESS_TYPE,
    ADDRESS_USE,
    ADJUSTABLE_DISPLAY,
    ADJUSTABLE_RATE,
    ADJUSTABLE_TYPE,
    CHARGE_ITEM_STATUS,
    COMPANY_ROLE,
    COMPANY_USER_ACCOUNT_RELATION_TYPE,
    CONTACT_ENTITY_TYPE,
    CONTACT_POINT_SYSTEM,
    CONTACT_POINT_USE,
    COUNTRY_NAMES,
    COUNTRY_ALPHA2_CODES,
    COUNTRY_ALPHA3_CODES,
    DATABASE_NAMES,
    EMPLOYEE_ROLE,
    FORM_STATE,
    GENDER,
    GENERIC_FIELD_TYPE,
    IMAGE_SIZE,
    INVOICE_BILL_STATUS,
    INVOICE_FREQUENCY,
    INVOICE_GROUP_BY,
    INVOICE_SEND_VIA,
    INVOICE_STATE,
    INVOICE_STATUS,
    LANGUAGES,
    NAME_USE,
    TRANSACTION_CATEGORY,
    PAYMENT_METHOD,
    TRANSACTION_TYPE,
    PAYOR_TYPE,
    PRICE_COMPONENT_TYPE,
    PRIMED_APP,
    PRIMED_CONFIG_KEY,
    PRIMED_EMPLOYER_USER_ROLE,
    PRIMED_REVIEWER_USER_ROLE,
    LEGACY_SECURITY_GROUP,
    LEGACY_SEX,
    UNITED_STATES,
    USER_GROUP,
    TEST_REASON,
    VISIT_STATE,
    WIDGET,
    parseGender,
}

import React from 'react'
import { Outlet } from 'react-router-dom'

// Redux
import AppToastError from '../components/app/AppToastError'

// Components

// Styles

function Default() {
    return (
        <>
            <Outlet />
            <AppToastError />
        </>
    )
}

export default Default

import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { apiGet, apiPost } from '../../../../utils/api'
import { selectSelectedLocation } from '../../../../redux/slices/employerSlice'
import { setAppError } from '../../../../redux/slices/appSlice'
import { Spinner } from '@primed/hyper'
import PageTitle from '../../../../components/app/PageTitle'

export default function JobCreate() {
    const dispatch = useDispatch()

    const { companyId: employerId } = useSelector(selectSelectedLocation)
    const [projects, setProjects] = useState([])
    const [projectsStatus, setProjectsStatus] = useState('load') // load, success, error

    const [existingJobs, setExistingJobs] = useState([])
    const [jobNumber, setJobNumber] = useState('')
    const [projectId, setProjectId] = useState(projects[0]?.projectId)
    const [projectCompanyId, setProjectCompanyId] = useState(null)
    const [status, setStatus] = useState('edit') // edit, load, save, success, error
    const formRef = useRef(null)

    // Initial load to fetch projects
    useEffect(() => {
        async function fetchProjects() {
            const response = await apiGet('protected/employer/settings/projects/list', { employerId })
            if (response.error) {
                console.log(response.error)
                setProjectsStatus('error')
            } else {
                setProjectsStatus('success')
                setProjects(response.projects)
                if (response.projects.length) {
                    setProjectId(response.projects[0].projectId)
                }
            }
        }

        void fetchProjects()
    }, [])

    // Load job numbers for this location based on the selected project, re-fetch on new project selections
    useEffect(() => {
        if (!projectId) {
            return
        }

        async function fetchJobNumbers() {
            let response = await apiPost('protected/employer/settings/jobs/project-company-id', {
                projectId,
                employerId,
            })
            if (response.error) {
                throw response.error
            }

            const { projectCompanyId } = response

            setProjectCompanyId(projectCompanyId)

            response = await apiGet('protected/employer/settings/jobs/list', {
                employerId,
                projectCompanyId,
            })
            if (response.error) {
                throw response.error
            }

            setExistingJobs(response.jobs)
        }

        setStatus('load')

        fetchJobNumbers()
            .then(() => setStatus('edit'))
            .catch((error) => {
                console.error(error)
                setAppError({ error: 'Failed to load current jobs.' })
                setStatus('error')
            })
    }, [projectId])

    function handleChangeJobNumber(event) {
        const jobNum = event.target.value
        setJobNumber(jobNum)

        if (existingJobs.find((job) => job.jobNumber === jobNum)) {
            event.target.setCustomValidity('Job numbers must be unique.')
        } else {
            event.target.setCustomValidity('')
        }
    }

    async function handleSubmit(event) {
        event.preventDefault()

        const valid = formRef.current?.checkValidity()
        formRef.current?.classList.add('was-validated')

        if (!valid) {
            event.stopPropagation()
            return
        }

        try {
            setStatus('save')
            const payload = {
                employerId,
                projectCompanyId,
                jobNumber,
            }
            const response = await apiPost('protected/employer/settings/jobs/create', payload)
            if (response.error) {
                console.log('error')
                setStatus('error')
            } else {
                setStatus('success')
                setExistingJobs([...existingJobs, { projectCompanyJobId: response.projectCompanyJobId, jobNumber }])
                setJobNumber('')
            }
        } catch (error) {
            console.error(error)
            dispatch(setAppError({}))
        }
    }

    function renderForm() {
        return (
            <form className="needs-validation mb-3" ref={formRef} onSubmit={handleSubmit} noValidate>
                <fieldset className="row" disabled={['load', 'save'].includes(status)}>
                    <div className="col">
                        <label htmlFor="project-select" className="form-label">
                            Project
                        </label>
                        <select className="form-select" id="project-select" value={`${projectId}`} onChange={(e) => setProjectId(parseInt(e.target.value))} required>
                            {projects.map(({ projectId, projectName, projectNumber }) => (
                                <option key={projectId} value={projectId}>
                                    {projectName} ({projectNumber})
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col">
                        <label className="form-label">Job Number</label>
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="JOB2023" aria-label="Job Number" onChange={handleChangeJobNumber} required />
                            <button className="btn btn-success" type="submit">
                                <ion-icon name="add" />
                                <span className="ms-1">Add</span>
                            </button>
                            <div className="invalid-feedback">Job number is required and must be unique.</div>
                        </div>
                    </div>
                </fieldset>
            </form>
        )
    }

    function renderExistingJobs() {
        return (
            <>
                {status === 'load' && (
                    <div className="d-flex align-items-center alert alert-secondary" role="alert">
                        <Spinner />
                        <span className="ms-3">Loading existing job numbers...</span>
                    </div>
                )}

                {status !== 'load' && existingJobs.length > 0 && (
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Job Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {existingJobs.map(({ jobNumber }) => (
                                <tr key={jobNumber}>
                                    <td>{jobNumber}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}

                {status !== 'load' && !existingJobs.length && (
                    <div className="alert alert-secondary" role="alert">
                        <p>No jobs currently available for this location. Please use the form above to create one now.</p>
                        <p>Notice: New service requests are required to specify a job number at the time of the request.</p>
                    </div>
                )}
            </>
        )
    }

    function renderCard() {
        return (
            <div className="card">
                <div className="card-header">
                    <h4>Create Job</h4>
                </div>

                <div className="card-body">
                    <p>
                        Use the following form to select a project and create a new job within that project. Projects serve as a means to organize your jobs.
                        <br></br>
                        Please also note that job numbers must be unique, and a list of existing job numbers for this location has been provided below for your convenience.
                    </p>

                    {renderForm()}

                    {status === 'save' && (
                        <div className="alert alert-warning" role="alert">
                            <Spinner />
                            <span className="ms-3">Adding job number...</span>
                        </div>
                    )}

                    {status === 'success' && (
                        <div className="alert alert-success" role="alert">
                            <ion-icon name="checkmark" />
                            Successfully added job number!
                        </div>
                    )}

                    {status === 'error' && (
                        <div className="alert alert-danger" role="alert">
                            <ion-icon name="close" />
                            <span className="ms-1">Failed to add job number. Please try again later.</span>
                        </div>
                    )}

                    {renderExistingJobs()}
                </div>
            </div>
        )
    }

    return (
        <>
            <PageTitle taskTitle={'Create Job'} />

            {projectsStatus === 'load' && (
                <div className="alert alert-secondary" role="alert">
                    <Spinner />
                    <span className="ms-3">Loading projects...</span>
                </div>
            )}

            {projectsStatus === 'error' && (
                <div className="alert alert-danger" role="alert">
                    <ion-icon name="close" />
                    <span className="ms-1">Failed to load projects. Please try again later.</span>
                </div>
            )}

            {projectsStatus === 'success' && projects.length === 0 && (
                <div className="alert alert-danger" role="alert">
                    <ion-icon name="close"></ion-icon>
                    No projects available. Jobs must be created relative to a project. Please contact
                    <a rel="noreferrer" target="_blank" href="mailto:eme.support@primeoccmed.com">
                        EME Support
                    </a>{' '}
                    for further assistance.
                </div>
            )}

            {projectsStatus === 'success' && projects.length > 0 && renderCard()}
        </>
    )
}

const { v4: uuidv4 } = require('uuid')
const clock = require('./clock')
const enums = require('./enums')
const sanitizers = require('./sanitizers')

module.exports = {
    clock,
    enums,
    sanitizers,
    randomUuid: () => uuidv4(),
}

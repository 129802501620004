import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { apiPut } from '../../utils/api'
import { setAppError } from '../../redux/slices/appSlice'
import { $logIn } from '../../redux/slices/userSlice'
import { Spinner } from '@primed/hyper'

export default function ConfirmForgotPassword({ location }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [searchParams] = useSearchParams()
    const [email, setEmail] = useState(searchParams.get('email') || '')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [status, setStatus] = useState('edit')

    const token = searchParams.get('token')

    const comparePasswords = (value) => {
        setConfirmPassword(value)
    }

    const confirmNewPassword = async (evt) => {
        try {
            evt.preventDefault()
            if (password !== confirmPassword) {
                setStatus('error') // this is more like a validation error state -- not an API error state
                return
            }

            const userInfo = {
                token,
                email,
                password,
            }

            setStatus('loading')

            const response = await apiPut('public/account/password/confirm', userInfo)
            if (response.error) {
                // API errors just post a toast to the global error state
                if (response.error.includes('Validation')) {
                    setStatus('invalid')
                    dispatch(setAppError({ error: 'Please verify your password meets the security requirements.' }))
                } else {
                    dispatch(setAppError({ error: 'Failed to set new user password.' }))
                    setStatus('edit')
                }
            } else {
                dispatch(
                    $logIn(email, password, (path) => {
                        if (path) {
                            navigate(path)
                        } else {
                            navigate('/user/login?error=true')
                        }
                    })
                )
            }
        } catch (error) {
            console.error(error)
            setStatus('edit')
        }
    }

    return (
        <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xxl-4 col-lg-5">
                        <div className="card">
                            <div className="card-header pt-4 pb-4 text-center bg-primary">
                                <Link to="/user/login">
                                    <span>
                                        <img className="login-header-logo" src="/img/prime-logo_white.png" alt="PRIME" />
                                    </span>
                                </Link>
                            </div>
                            <div className="card-body p-4">
                                <div className="text-center w-75 m-auto">
                                    <h4 className="text-dark-50 text-center mt-0 fw-bold">Reset Password</h4>
                                    <p className="text-muted mb-4">Set your new password</p>
                                </div>
                                <form id="confirmPasswordForm" onSubmit={confirmNewPassword}>
                                    <div className="mb-3">
                                        <label htmlFor="username" className="form-label required">
                                            Username
                                        </label>
                                        <input
                                            tabIndex={1}
                                            className="form-control"
                                            type="email"
                                            id="username"
                                            required
                                            placeholder="Enter your email"
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value)
                                            }}
                                            autoFocus
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label required">
                                            New Password
                                        </label>
                                        <input
                                            tabIndex={2}
                                            className="form-control"
                                            type="password"
                                            id="password"
                                            required
                                            placeholder="Enter your password"
                                            value={password}
                                            onChange={(e) => {
                                                setPassword(e.target.value)
                                            }}
                                        />
                                        <span className={`help-block ${status === 'invalid' ? 'text-danger' : ''}`}>
                                            <small>Requires minimum of 8 characters: one capital, one lowercase, one symbol, and one number.</small>
                                        </span>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="confirmPassword" className="form-label required">
                                            Confirm Password
                                        </label>
                                        <input
                                            tabIndex={3}
                                            className="form-control"
                                            type="password"
                                            id="confirmPassword"
                                            required
                                            placeholder="Reenter your password"
                                            value={confirmPassword}
                                            onChange={(e) => {
                                                comparePasswords(e.target.value)
                                            }}
                                        />
                                        {status === 'error' && (
                                            <span className={`help-block text-danger`}>
                                                <small>Password and confirmation do not match.</small>
                                            </span>
                                        )}
                                    </div>
                                </form>
                                <div className="mb-3 mb-0 text-center">
                                    {status === 'loading' && (
                                        <div className="d-flex align-items-center justify-content-center">
                                            <Spinner />
                                            <span className="ps-1">Saving...</span>
                                        </div>
                                    )}
                                    {(status === 'edit' || status === 'error' || status === 'invalid') && (
                                        <button tabIndex={4} type="submit" className="btn btn-primary" form="confirmPasswordForm" disabled={!email || !password || !confirmPassword}>
                                            Update Password and Login
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

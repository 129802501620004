import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { apiPut } from '../../utils/api'

// Redux
import { setAppError } from '../../redux/slices/appSlice'

// Components
import { Button, Spinner } from '@primed/hyper'

// Styles

export default function ProfilePhone({ phone: phoneProp, userAccountId }) {
    const dispatch = useDispatch()

    const [phone, setPhone] = useState(phoneProp || '')
    const [status, setStatus] = useState('read')
    const [original, setOriginal] = useState(phoneProp)
    const [valid, setValid] = useState('')

    function onCancel() {
        setPhone(original || '')
        setStatus('read')
    }

    const onEdit = () => setStatus('edit')

    async function onSave(event) {
        event.preventDefault()
        try {
            setStatus('load')
            const payload = {
                userAccountId,
                phone: phone.replaceAll(/[\D\s]+/g, ''),
            }
            const response = await apiPut('protected/employer/settings/users/profile/phone', payload)

            if (response.error) {
                dispatch(setAppError({ error: response.error }))
                if (response.validation) {
                    setValid(response.validation)
                }
                setStatus('edit')
            } else {
                setValid('')
                setOriginal(payload.phone)
                setPhone(payload.phone)
                setStatus('read')
            }
        } catch (e) {
            setStatus('edit')
        }
    }

    return (
        <>
            <form className="col container card" onSubmit={onSave}>
                <div className="card-header">
                    <div className="d-flex justify-content-between">
                        <h5>Phone</h5>

                        <div>
                            {status === 'read' && <Button title="Edit" cb={onEdit}></Button>}
                            {status === 'edit' && <Button title="Cancel" cb={onCancel}></Button>}
                            {status === 'edit' && <Button title="Save" classes={['btn-outline-success']} type="submit"></Button>}
                            {status === 'load' && (
                                <div className="d-flex align-items-center">
                                    <Spinner />
                                    <span className="ps-1">Saving...</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="card-body">
                    {!!valid && <p className="small text-danger">Please enter a US 10 digit phone number.</p>}
                    <div>
                        <fieldset disabled={status === 'edit' ? undefined : 'disabled'}>
                            <div className="form-floating mb-3">
                                <input type="tel" id="phone" className="form-control" placeholder="111-222-4444" required="true" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                <label htmlFor="phone" className="form-label">
                                    Phone
                                </label>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </form>
        </>
    )
}

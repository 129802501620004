import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { apiPut } from '../../utils/api'

// Redux
import { setAppError } from '../../redux/slices/appSlice'

// Components
import { Button, Spinner } from '@primed/hyper'

// Styles

function ProfileExtendedIdentifiers({ info, reload }) {
    const dispatch = useDispatch()
    const [companyInfo, setCompanyInfo] = useState({})
    const [driverLicenseNumberRequired, setDriverLicenseNumberRequired] = useState(false)
    const [alternatePhoneRequired, setAlternatePhoneRequired] = useState(false)
    const [craftRequired, setCraftRequired] = useState(false)
    const [employeeNumberRequired, setEmployeeNumberRequired] = useState(false)
    const [jobNumberRequired, setJobNumberRequired] = useState(false)
    const [jobTitleRequired, setJobTitleRequired] = useState(false)
    const [locationRequired, setLocationRequired] = useState(false)
    const [status, setStatus] = useState('read')

    useEffect(() => {
        setCompanyInfo(info)
        setDriverLicenseNumberRequired(info.driverLicenseNumberRequired || false)
        setAlternatePhoneRequired(info.alternatePhoneRequired || false)
        setCraftRequired(info.craftRequired || false)
        setEmployeeNumberRequired(info.employeeNumberRequired || false)
        setJobNumberRequired(info.jobNumberRequired || false)
        setJobTitleRequired(info.jobTitleRequired || false)
        setLocationRequired(info.locationRequired || false)
    }, [info])

    const onEdit = () => setStatus('edit')

    const onCancel = async () => {
        setStatus('read')
        await reload()
    }

    const onSave = async (event) => {
        event.preventDefault()
        setStatus('load')
        const response = await apiPut(`protected/employer/settings/profile/extended-identifiers`, {
            employerId: parseInt(companyInfo.companyId),
            alternatePhoneRequired,
            craftRequired,
            driverLicenseNumberRequired,
            employeeNumberRequired,
            jobNumberRequired,
            jobTitleRequired,
            locationRequired,
        })

        if (response.error) {
            dispatch(setAppError({ error: response.error }))
            setStatus('edit')
        } else {
            setStatus('read')
            void reload()
        }
    }

    return (
        <form onSubmit={onSave}>
            <div className="card">
                <div className="card-header">
                    <div className="d-flex justify-content-between">
                        <h5>Extended Identifiers</h5>

                        <div>
                            {status === 'read' && <Button title="Edit" cb={onEdit}></Button>}
                            {status === 'edit' && <Button title="Cancel" cb={onCancel}></Button>}
                            {status === 'edit' && <Button type="submit" classes={['btn-outline-success']} title="Save"></Button>}
                            {status === 'load' && (
                                <div className="d-flex align-items-center">
                                    <Spinner />
                                    <span className="ps-1">Saving...</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <h5>Questionnaires</h5>
                    <p className="text-muted mb-4">
                        <span className="me-2">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                name="licenseNumber"
                                checked={driverLicenseNumberRequired}
                                disabled={status === 'read' ? 'disabled' : ''}
                                onChange={(evt) => setDriverLicenseNumberRequired(evt.target.checked)}
                            />
                        </span>
                        <strong>License Number</strong>{' '}
                    </p>
                    <hr className="bg-secondary border-1 border-top border-light mb-2" />
                    <h5>Reviews</h5>
                    <div className="row">
                        <div className="col-4">
                            <p className="text-muted">
                                <span className="me-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="alternatePhone"
                                        checked={alternatePhoneRequired}
                                        disabled={status === 'read' ? 'disabled' : ''}
                                        onChange={(evt) => setAlternatePhoneRequired(evt.target.checked)}
                                    />
                                </span>
                                <strong>Alternate Phone</strong>{' '}
                            </p>
                        </div>
                        <div className="col-4">
                            <p className="text-muted">
                                <span className="me-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="craft"
                                        checked={craftRequired}
                                        disabled={status === 'read' ? 'disabled' : ''}
                                        onChange={(evt) => setCraftRequired(evt.target.checked)}
                                    />
                                </span>
                                <strong>Craft</strong>{' '}
                            </p>
                        </div>
                        <div className="col-4">
                            <p className="text-muted">
                                <span className="h-100 me-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="employeeNumber"
                                        checked={employeeNumberRequired}
                                        disabled={status === 'read' ? 'disabled' : ''}
                                        onChange={(evt) => setEmployeeNumberRequired(evt.target.checked)}
                                    />
                                </span>
                                <strong>Employee Number</strong>{' '}
                            </p>
                        </div>
                        <div className="col-4">
                            <p className="text-muted">
                                <span className="me-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="jobNumber"
                                        checked={jobNumberRequired}
                                        disabled={status === 'read' ? 'disabled' : ''}
                                        onChange={(evt) => setJobNumberRequired(evt.target.checked)}
                                    />
                                </span>
                                <strong>Job Number</strong>{' '}
                            </p>
                        </div>
                        <div className="col-4">
                            <p className="text-muted">
                                <span className="me-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="jobTitle"
                                        checked={jobTitleRequired}
                                        disabled={status === 'read' ? 'disabled' : ''}
                                        onChange={(evt) => setJobTitleRequired(evt.target.checked)}
                                    />
                                </span>
                                <strong>Job Title</strong>{' '}
                            </p>
                        </div>
                        <div className="col-4 ">
                            <p className="text-muted">
                                <span className="me-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="location"
                                        checked={locationRequired}
                                        disabled={status === 'read' ? 'disabled' : ''}
                                        onChange={(evt) => setLocationRequired(evt.target.checked)}
                                    />
                                </span>
                                <strong>Location</strong>{' '}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default ProfileExtendedIdentifiers

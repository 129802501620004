import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { selectUserIsAuthenticated } from './redux/slices/userSlice'
import * as serviceWorker from './serviceWorker.js'

// Redux
import { persistor, store } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider, useSelector } from 'react-redux'

// Layouts
import Default from './layouts/Default'
import App from './layouts/App'

// Pages
import Login from './pages/user/Login'
import ForgotPassword from './pages/user/ForgotPassword'
import ConfirmForgotPassword from './pages/user/ConfirmForgotPassword'

import Dashboard from './pages/employer/Dashboard'
import ServiceRequest from './pages/employer/tasks/service-request/ServiceRequest'
import EmployeeReports from './pages/employer/employee/EmployeeReports'
import CompanyProfile from './pages/employer/settings/company/CompanyProfile'
import UserList from './pages/employer/settings/users/UserList'
import UserProfile from './pages/employer/settings/users/UserProfile'
import UserInvite from './pages/employer/settings/users/UserInvite'
import JobCreate from './pages/employer/settings/jobs/JobCreate'

import Error from './pages/Error'

// PriMed Hyper Components
import '@primed/hyper/dist/index.scss'
import '@primed/hyper/dist/hyper.scss'
import './index.scss'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <BrowserRouter>
                <Routes>
                    <Route path="/user" element={<Unathenticated page={<Default />} redirect="/employer" />}>
                        <Route path="login" element={<Login />} />
                        <Route path="confirm-forgot-password" element={<ConfirmForgotPassword />}></Route>
                        <Route path="forgot-password" element={<ForgotPassword />}></Route>
                    </Route>
                    <Route path="/employer" element={<Authenticated page={<App />} redirect="/user/login" />}>
                        <Route index element={<Dashboard />} />
                        <Route path="service-request" element={<ServiceRequest />}></Route>
                        <Route path="employee-reports" element={<EmployeeReports />}></Route>
                        <Route path="profile-setup" element={<CompanyProfile />}></Route>
                        <Route path="settings">
                            <Route path="*" element={<Navigate replace to="/employer/settings/users" />} />
                            <Route path="jobs/create" element={<JobCreate />}></Route>
                            <Route path="users" element={<UserList />}></Route>
                            <Route path="users/:userAccountId" element={<UserProfile />}></Route>
                            <Route path="users/invite-user" element={<UserInvite />}></Route>
                        </Route>
                    </Route>
                    <Route path="/" element={<Default />}>
                        <Route index element={<Unathenticated page={<Login />} redirect="/employer" />} />
                        <Route path="*" element={<Error />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </PersistGate>
    </Provider>
)

function Authenticated({ page, redirect }) {
    const navigate = useNavigate()
    const userIsAuthenticated = useSelector(selectUserIsAuthenticated)
    useEffect(() => {
        if (!userIsAuthenticated) {
            navigate(redirect)
        }
    }, [userIsAuthenticated])
    return page
}

function Unathenticated({ page, redirect }) {
    const navigate = useNavigate()
    const userIsAuthenticated = useSelector(selectUserIsAuthenticated)
    useEffect(() => {
        if (userIsAuthenticated) {
            navigate(redirect)
        }
    }, [userIsAuthenticated])
    return page
}

serviceWorker.unregister()

/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { $logIn } from '../../redux/slices/userSlice'

import './Login.scss'

function Login() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const submitLogIn = () => {
        dispatch(
            $logIn(username, password, (path) => {
                if (path) {
                    navigate(path)
                } else {
                    navigate('/user/login?error=true')
                }
            })
        )
    }

    return (
        <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xxl-4 col-lg-5">
                        <div className="card">
                            <div className="card-header pt-4 pb-4 text-center bg-primary">
                                <Link to="/user/login">
                                    <span>
                                        <img className="login-header-logo" src="/img/prime-logo_white.png" alt="PRIME" />
                                    </span>
                                </Link>
                            </div>

                            <div className="card-body p-4">
                                <div className="text-center w-75 m-auto">
                                    <h4 className="text-dark-50 text-center mt-0 fw-bold">Sign In</h4>
                                    <p className="text-muted mb-4">Enter your email address and password to access the PRIME employer portal.</p>
                                </div>

                                <form>
                                    <div className="mb-3">
                                        <label htmlFor="emailaddress" className="form-label required">
                                            Email address
                                        </label>
                                        <input
                                            tabIndex={1}
                                            className="form-control"
                                            type="email"
                                            id="emailaddress"
                                            required
                                            placeholder="Enter your email"
                                            value={username}
                                            onChange={(e) => {
                                                setUsername(e.target.value)
                                            }}
                                            autoFocus={true}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <Link to="/user/forgot-password" className="text-muted float-end">
                                            <small tabIndex={3}>Forgot your password?</small>
                                        </Link>
                                        <label htmlFor="password" className="form-label required">
                                            Password
                                        </label>
                                        <div className="input-group input-group-merge">
                                            <input
                                                tabIndex={2}
                                                type={showPassword ? 'text' : 'password'}
                                                id="password"
                                                className="form-control"
                                                placeholder="Enter your password"
                                                value={password}
                                                onChange={(e) => {
                                                    setPassword(e.target.value)
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        submitLogIn()
                                                    }
                                                }}
                                            />
                                            <div className="input-group-text" data-password="false">
                                                <ion-icon classes={['icon-md']} name={showPassword ? 'eye' : 'eye-off-outline'} onClick={() => setShowPassword(!showPassword)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3 mb-0 text-center">
                                        <button tabIndex={3} type="button" className="btn btn-primary" onClick={submitLogIn} disabled={!username || !password}>
                                            Log In
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                        {/* <div className="row mt-3">
                            <div className="col-12 text-center">
                                <p className="text-muted">
                                    Dont have an account?{' '}
                                    <Link to="/user/signup" className="text-muted ms-1">
                                        <b>Sign Up</b>
                                    </Link>
                                </p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login

import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { apiPost } from '../../utils/api'

// Redux
import { setAppError } from '../../redux/slices/appSlice'

// Components
import { Button } from '@primed/hyper'

// Styles

function TabPatientsNewPatient({ serviceRequest, setServiceRequest, patients, setPatients }) {
    const dispatch = useDispatch()

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [dob, setDob] = useState('')
    const [ssn, setSsn] = useState('')

    const handleAddNewPatient = async () => {
        try {
            const res = await apiPost('protected/employer/service-request/new-patient/', { firstName, lastName, dob, ssn })
            if (res.patient && !patients.filter((pat) => pat.patientId === res.patient.patientId).length) {
                setPatients([...patients, { firstName, lastName, dateOfBirth: dob, ssn, patientId: res.patient.patientId }])
                setServiceRequest({ ...serviceRequest, patients: [...patients, { patientId: res.patient.patientId, firstName, lastName, dateOfBirth: dob, ssn }] })
                setFirstName('')
                setLastName('')
                setDob('')
                setSsn('')
            } else if (res.error) {
                dispatch(setAppError({ error: res.error }))
            }
        } catch (e) {
            console.log(e)
        }
    }
    return (
        <>
            <div className="col-12">
                <div className="row">
                    <div className="col-3">
                        <label for="simpleinput" className="form-label">
                            First Name
                        </label>
                        <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            value={firstName}
                            onChange={(e) => {
                                setFirstName(e.target.value)
                            }}
                        />
                    </div>
                    <div className="col-3">
                        <label for="simpleinput" className="form-label">
                            Last Name
                        </label>
                        <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            value={lastName}
                            onChange={(e) => {
                                setLastName(e.target.value)
                            }}
                        />
                    </div>
                    <div className="col-3">
                        <label for="simpleinput" className="form-label">
                            Date of Birth
                        </label>
                        <input
                            type="date"
                            id="simpleinput"
                            className="form-control"
                            value={dob}
                            onChange={(e) => {
                                setDob(e.target.value)
                            }}
                        />
                    </div>
                    <div className="col-3">
                        <label for="simpleinput" className="form-label">
                            Social Security Number
                        </label>
                        <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            value={ssn}
                            onChange={(e) => {
                                setSsn(e.target.value.replace(/\D/g, '').substring(0, 9))
                            }}
                        />
                    </div>
                </div>
                <div className="row mt-3 mb-2 d-flex justify-content-center">
                    <Button title="Add Employee" className="btn btn-info w-25" disabled={!firstName || !lastName || !dob || !ssn} cb={handleAddNewPatient} />
                </div>
            </div>
        </>
    )
}

export default TabPatientsNewPatient

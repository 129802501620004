import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { apiPut } from '../../utils/api'
import { enums } from '@primed/util-client'

// Redux
import { setAppError } from '../../redux/slices/appSlice'

// Components
import { Button, Spinner } from '@primed/hyper'

// Styles

export default function ProfileCompanyInformation({ info, reload }) {
    const dispatch = useDispatch()
    const [companyInfo, setCompanyInfo] = useState({})
    const [status, setStatus] = useState('read')
    const [address1, setAddress1] = useState('')
    const [address2, setAddress2] = useState('')
    const [addressState, setAddressState] = useState('')
    const [addressCity, setAddressCity] = useState('')
    const [addressPostalCode, setAddressPostalCode] = useState('')

    useEffect(() => {
        setCompanyInfo(info)
        const { address1, address2, addressCity, addressState, addressPostalCode } = info
        setAddress1(address1 || '')
        setAddress2(address2 || '')
        setAddressState(addressState || '')
        setAddressCity(addressCity || '')
        setAddressPostalCode(addressPostalCode || '')
    }, [info])

    const onEdit = () => setStatus('edit')

    const onSave = async (evt) => {
        evt.preventDefault()
        setStatus('load')
        const response = await apiPut(`protected/employer/settings/profile/address`, {
            employerId: companyInfo.companyId,
            address1,
            address2,
            addressState,
            addressCity,
            addressPostalCode,
        })
        if (response.error) {
            dispatch(setAppError({ error: response.error }))
            setStatus('edit')
        } else {
            await reload()
            setStatus('read')
        }
    }

    const onCancel = async () => {
        const { address1, address2, addressState, addressCity, addressPostalCode } = companyInfo
        setStatus('read')
        setAddress1(address1)
        setAddress2(address2)
        setAddressState(addressState)
        setAddressCity(addressCity)
        setAddressPostalCode(addressPostalCode)
    }

    return (
        <form onSubmit={onSave}>
            <div className="card">
                <div className="card-header">
                    <div className="d-flex justify-content-between">
                        <h5>Information</h5>

                        <div>
                            {status === 'read' && <Button title="Edit" cb={onEdit}></Button>}
                            {status === 'edit' && <Button title="Cancel" cb={onCancel}></Button>}
                            {status === 'edit' && <Button type="submit" classes={['btn-outline-success']} title="Save"></Button>}
                            {status === 'load' && (
                                <div className="d-flex align-items-center">
                                    <Spinner />
                                    <span className="ps-1">Saving...</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="card-body">
                    <fieldset disabled={status === 'edit' ? undefined : 'disabled'}>
                        <div className="row">
                            <div className="col-8">
                                <div className="form-floating mb-3">
                                    <input type="text" id="address1" className="form-control" placeholder="Address 1" value={address1} onChange={(e) => setAddress1(e.target.value)} requried="true" />
                                    <label htmlFor="address1" className="form-label">
                                        Address 1
                                    </label>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        id="addressCity"
                                        className="form-control"
                                        placeholder="City"
                                        value={addressCity}
                                        onChange={(e) => setAddressCity(e.target.value)}
                                        requried="true"
                                    />
                                    <label htmlFor="addressCity" className="form-label">
                                        City
                                    </label>
                                </div>
                            </div>
                            <div className="col-8">
                                <div className="form-floating mb-3">
                                    <input type="text" id="address2" className="form-control" placeholder="Address 2" value={address2} onChange={(e) => setAddress2(e.target.value)} />
                                    <label htmlFor="address2" className="form-label">
                                        Address 2
                                    </label>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        id="addressPostalCode"
                                        className="form-control"
                                        placeholder="Postal Code"
                                        value={addressPostalCode}
                                        onChange={(e) => setAddressPostalCode(e.target.value)}
                                        requried="true"
                                    />
                                    <label htmlFor="addressPostalCode" className="form-label">
                                        Zip
                                    </label>
                                </div>
                            </div>
                            <div className="col-4 offset-8">
                                <div className="form-floating mb-3">
                                    <select className="form-select" id="addressState" value={addressState} onChange={(e) => setAddressState(e.target.value)} requried="true">
                                        <option value="">Select a state</option>
                                        {Object.entries(enums.UNITED_STATES).map(([key, val]) => (
                                            <option key={key} value={val}>
                                                {key}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor="addressState" className="form-label">
                                        State
                                    </label>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </form>
    )
}

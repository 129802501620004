import React from 'react'
import { Link } from 'react-router-dom'

function TabSuccess(props) {
    return (
        <div className="row">
            <div className="col">
                <div className="text-center">
                    <h2 className="mt-0">
                        <ion-icon name="checkmark-circle-outline"></ion-icon>
                    </h2>
                    <h3 className="mt-0">Service Request Has Been Created</h3>

                    <p className="w-75 mb-2 mx-auto">
                        A service request has been successfully created for <b>{location?.companyName}</b>.
                    </p>

                    <Link to="/employer#refresh" className="btn btn-primary">
                        Back to Dashboard
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default TabSuccess

import React, { useState } from 'react'
import { apiPut } from '../../utils/api'
import { enums } from '@primed/util-client'
import { useDispatch } from 'react-redux'

// Redux
import { setAppError } from '../../redux/slices/appSlice'

// Components
import { Button, Spinner } from '@primed/hyper'

// Styles

export default function ProfileAddress({ address, userAccountId }) {
    const dispatch = useDispatch()

    const [address1, setAddress1] = useState(address.address1 || '')
    const [address2, setAddress2] = useState(address.address2 || '')
    const [city, setCity] = useState(address.city || '')
    const [state, setState] = useState(address.state || '')
    const [postalCode, setPostalCode] = useState(address.postalCode || '')
    const [status, setStatus] = useState('read')
    const [original, setOriginal] = useState(address)

    function onCancel() {
        setAddress1(original.address1 || '')
        setAddress2(original.address2 || '')
        setCity(original.city || '')
        setState(original.state || '')
        setPostalCode(original.postalCode || '')
        setStatus('read')
    }

    const onEdit = () => setStatus('edit')

    async function onSave(event) {
        event.preventDefault()

        try {
            setStatus('load')
            const address = {
                address1,
                address2,
                city,
                state,
                postalCode,
            }
            const payload = {
                userAccountId,
                address,
            }

            const response = await apiPut('protected/employer/settings/users/profile/address', payload)

            if (response.error) {
                dispatch(setAppError({ error: response.error }))
                status('edit')
            } else {
                setOriginal(address)
                setStatus('read')
            }
        } catch (e) {
            setStatus('edit')
        }
    }

    return (
        <>
            <form className="col container card" onSubmit={onSave}>
                <div className="card-header">
                    <div className="d-flex justify-content-between">
                        <h5>Address</h5>

                        <div>
                            {status === 'read' && <Button title="Edit" cb={onEdit}></Button>}
                            {status === 'edit' && <Button title="Cancel" cb={onCancel}></Button>}
                            {status === 'edit' && <Button title="Save" classes={['btn-outline-success']} type="submit"></Button>}
                            {status === 'load' && (
                                <div className="d-flex align-items-center">
                                    <Spinner />
                                    <span className="ps-1">Saving...</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="card-body">
                    <div>
                        <fieldset disabled={status === 'edit' ? undefined : 'disabled'}>
                            <div className="form-floating mb-3">
                                <input type="text" id="address1" required="true" className="form-control" placeholder="123 ABC Street" value={address1} onChange={(e) => setAddress1(e.target.value)} />
                                <label htmlFor="address1" className="form-label">
                                    Address Line 1
                                </label>
                            </div>

                            <div className="form-floating mb-3">
                                <input type="text" id="address2" className="form-control" placeholder="Suite D" value={address2} onChange={(e) => setAddress2(e.target.value)} />
                                <label htmlFor="address2" className="form-label">
                                    Address Line 2
                                </label>
                            </div>

                            <div className="form-floating mb-3">
                                <input type="text" id="city" required="true" className="form-control" placeholder="Baton Rouge" value={city} onChange={(e) => setCity(e.target.value)} />
                                <label htmlFor="city" className="form-label">
                                    City
                                </label>
                            </div>

                            <div className="form-floating mb-3">
                                <select className="form-select" id="state" defaultValue={state} onChange={(e) => setState(e.target.value)} required="true">
                                    <option value="" disabled>
                                        Select a state
                                    </option>
                                    {Object.entries(enums.UNITED_STATES).map(([key, val]) => (
                                        <option key={key} value={val}>
                                            {key}
                                        </option>
                                    ))}
                                </select>
                                <label htmlFor="state">State</label>
                            </div>

                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    id="postalCode"
                                    required
                                    className="form-control"
                                    placeholder="77777"
                                    pattern="(\d+|\d+-\d+)"
                                    value={postalCode}
                                    onChange={(e) => setPostalCode(e.target.value)}
                                />
                                <label htmlFor="postalCode" className="form-label">
                                    Zip Code
                                </label>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </form>
        </>
    )
}

import { createSlice } from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode'
import { setAppError } from './appSlice'
import { clearDashboard } from './dashboardSlice'
import { clearEmployer } from './employerSlice'
import { apiPost, apiGet } from '../../utils/api'

const initialState = {
    banner: null,
    employerId: null,
    email: '',
    token: '',
    userId: null,
    rootRole: '',
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        signInUser: (state, action) => {
            state.banner = action.payload.banner
            state.email = action.payload.email
            state.token = action.payload.token
            state.userId = action.payload.userId || state.userId
        },
        signOutUser: (state) => {
            return initialState
        },
        setEmployerInformation: (state, action) => {
            const { employerId, token } = action.payload
            state.employerId = employerId || state.employerId
            state.token = token || state.token
        },
        setRole: (state, action) => {
            state.role = action.payload.role
        },
    },
})

export const { signInUser, signOutUser, setEmployerInformation, setRole } = userSlice.actions

// Selectors

export const selectUserIsAuthenticated = (state) => {
    if (!state.user?.token) {
        return false
    }
    const userToken = jwtDecode(state.user.token)
    const jwtExpires = userToken.exp * 1000
    const jwtIsActive = jwtExpires > Date.now()
    if (jwtIsActive) {
        return true
    }
    return false
}

export const selectBanner = (state) => state.user.banner
export const selectUsername = (state) => state.user.email?.split('@')?.[0]
export const selectEmail = (state) => state.user.email
export const selectToken = (state) => state.user.token
export const selectIsLoggedIn = (state) => (state.user.token ? true : false)
export const selectUserId = (state) => state.user.userId
export const selectRole = (state) => state.user.role

// Thunks

export const $logIn = (username, password, cb) => async (dispatch) => {
    if (!username.length || !password.length) {
        dispatch(setAppError({ error: 'Email and password are required' }))
        return
    }
    const res = await apiPost('public/account/login', { username, password }, null, dispatch)
    if (!res.error && res.token) {
        if (res.reset) {
            cb(`/user/confirm-forgot-password?email=${encodeURIComponent(username)}&token=${encodeURIComponent(res.token)}`)
            return
        }

        await dispatch(
            signInUser({
                banner: res.user?.banner,
                token: res.token,
                email: username,
                userId: res.user?.userAccountID,
            })
        )
        cb('/employer')
    } else {
        dispatch(setAppError({ error: res.error }))
        cb('/')
    }
}

export const $logOut = (cb) => async (dispatch) => {
    window.sessionStorage.clear()
    window.localStorage.clear()
    await dispatch(signOutUser({}))
    await dispatch(clearDashboard())
    await dispatch(clearEmployer())
    cb()
}

export const $getUserRoles = (rootOrFirstCompany) => async (dispatch) => {
    try {
        const role = await apiGet('protected/employer/settings/users/roles', { employerId: rootOrFirstCompany.companyId })
        if (role) {
            dispatch(setRole(role))
        }
    } catch (e) {
        dispatch(setAppError({ error: 'Could not fetch user permissions' }))
    }
}

export default userSlice.reducer

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { apiPut } from '../../utils/api'

// Redux
import { setAppError } from '../../redux/slices/appSlice'

// Components
import { Button, Spinner } from '@primed/hyper'

// Styles

function ProfilePayorInformation({ info, reload }) {
    const dispatch = useDispatch()
    const [companyInfo, setCompanyInfo] = useState({})
    const [status, setStatus] = useState('read')
    const [state, setState] = useState({
        accountsPayableName: '',
        accountsPayablePhone: '',
        accountsPayableFax: '',
        accountsPayableEmail: '',
    })
    const [validation, setValidation] = useState('')

    const onChange = (event) => {
        const { name, value } = event.target
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }

    useEffect(() => {
        setCompanyInfo(info)
        setState({
            accountsPayableName: info.accountsPayableName || '',
            accountsPayablePhone: info.accountsPayablePhone || '',
            accountsPayableFax: info.accountsPayableFax || '',
            accountsPayableEmail: info.accountsPayableEmail || '',
        })
    }, [info])

    const onEdit = () => setStatus('edit')

    const onSave = async (evt) => {
        evt.preventDefault()
        setStatus('load')
        const response = await apiPut(`protected/employer/settings/profile/contact`, {
            employerId: companyInfo.companyId,
            accountsPayableName: state.accountsPayableName,
            accountsPayablePhone: state.accountsPayablePhone,
            accountsPayableFax: state.accountsPayableFax,
            accountsPayableEmail: state.accountsPayableEmail,
        })
        if (response.error) {
            if (response.validation) {
                setValidation(response.validation)
                dispatch(setAppError({ error: response.error }))
            } else {
                dispatch(setAppError({ error: response.error }))
            }
            setStatus('edit')
        } else {
            setValidation('')
            await reload()
            setStatus('read')
        }
    }

    const onCancel = async () => {
        const { accountsPayableName, accountsPayablePhone, accountsPayableFax, accountsPayableEmail } = companyInfo
        setStatus('read')
        setState({
            accountsPayableName,
            accountsPayablePhone,
            accountsPayableFax,
            accountsPayableEmail,
        })
    }

    return (
        <form onSubmit={onSave}>
            <div className="card">
                <div className="card-header">
                    <div className="d-flex justify-content-between">
                        <h5>Payor Information</h5>

                        <div>
                            {status === 'read' && <Button title="Edit" cb={onEdit}></Button>}
                            {status === 'edit' && <Button title="Cancel" cb={onCancel}></Button>}
                            {status === 'edit' && <Button type="submit" classes={['btn-outline-success']} title="Save"></Button>}
                            {status === 'load' && (
                                <div className="d-flex align-items-center">
                                    <Spinner />
                                    <span className="ps-1">Saving...</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="card-body">
                    {!!validation && <p className="small text-danger mt-0 mb-2">Invalid Field: {validation}</p>}
                    {!!(state.accountsPayableName || state.accountsPayableEmail || state.accountsPayablePhone || state.accountsPayableFax || status === 'edit') && (
                        <fieldset disabled={status === 'edit' ? undefined : 'disabled'}>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            id="contact-name"
                                            className="form-control"
                                            required="true"
                                            placeholder="Name"
                                            name="accountsPayableName"
                                            value={state.accountsPayableName}
                                            onChange={onChange}
                                        />
                                        <label htmlFor="contact-name" className="form-label">
                                            Name (first and last)
                                        </label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input
                                            type="tel"
                                            id="phone-number"
                                            className="form-control"
                                            required="true"
                                            placeholder="Phone"
                                            name="accountsPayablePhone"
                                            value={state.accountsPayablePhone}
                                            onChange={onChange}
                                        />
                                        <label htmlFor="phone-number" className="form-label">
                                            Phone
                                        </label>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-floating mb-3">
                                        <input
                                            type="email"
                                            id="email-address"
                                            className="form-control"
                                            required="true"
                                            placeholder="Email"
                                            name="accountsPayableEmail"
                                            value={state.accountsPayableEmail}
                                            onChange={onChange}
                                        />
                                        <label htmlFor="email-address" className="form-label">
                                            Email
                                        </label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="tel" id="fax-number" className="form-control" placeholder="Fax" name="accountsPayableFax" value={state.accountsPayableFax} onChange={onChange} />
                                        <label htmlFor="fax-number" className="form-label">
                                            Fax
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    )}
                    {!!(state.accountsPayableName || state.accountsPayableEmail || state.accountsPayablePhone || state.accountsPayableFax) || status === 'edit' || (
                        <p>If no payor information is provided, all invoices will be sent to the parent company's payor.</p>
                    )}
                </div>
            </div>
        </form>
    )
}

export default ProfilePayorInformation

import React from 'react'

function TabVerify({ serviceRequest }) {
    return (
        <div className="row">
            <div className="col-12">
                <div className="text-center">
                    <h2 className="mt-0">
                        <ion-icon name="checkmark-circle-outline"></ion-icon>
                    </h2>
                    <h3 className="mt-0">Please Verify</h3>

                    <p className="w-75 mb-2 mx-auto">
                        You are about to create a service request for <b>{location?.companyName}</b> the following employees:
                        <br />
                        {serviceRequest.patients?.map((patient) => {
                            return (
                                <b key={patient.patientId}>
                                    {patient.firstName} {patient.lastName}
                                    <br />
                                </b>
                            )
                        })}
                        <br />
                        For the following services:
                        <br />
                        {serviceRequest.services?.map((service, idx) => {
                            return (
                                <b key={idx}>
                                    {service.testTypeName} - {service.testTypeDescription}
                                    <br />
                                </b>
                            )
                        })}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default TabVerify

// Get dashboard info place in state
import { createSlice } from '@reduxjs/toolkit'
import { setAppError } from './appSlice'
import { apiGet } from '../../utils/api'
import { $getUserRoles } from './userSlice'

const initialState = {
    locations: [],
    jobs: [],
    projects: [],
    reasons: [],
    statuses: [],
    selectedLocation: {},
}

export const employerSlice = createSlice({
    name: 'employer',
    initialState,
    reducers: {
        setOptions: (state, action) => {
            state.locations = action.payload?.locations ? [...action.payload.locations] : state.locations
            state.jobs = action.payload?.jobs ? [...new Set([...action.payload.jobs])] : state.jobs
            state.projects = action.payload?.projects ? [...new Set([...action.payload.projects])] : state.projects
            state.reasons = action.payload?.reasons || state.reasons
            state.statuses = action.payload?.statuses || state.statuses
            state.selectedLocation = action.payload?.selectedLocation ?? state.selectedLocation
        },
        setSelectedLocation: (state, action) => {
            state.selectedLocation = action.payload.location
        },
        clearEmployer: (state) => {
            return initialState
        },
    },
})

const { setOptions } = employerSlice.actions
export const { setSelectedLocation, clearEmployer } = employerSlice.actions

// Selectors

export const selectEmployerLocations = (state) => {
    return state.employer.locations
}
export const selectSelectedLocation = (state) => state.employer.selectedLocation
export const selectOptions = (state) => state.employer

// Thunks

export const $getEmployerOptions = () => async (dispatch, getState) => {
    const { employer, user } = getState()

    try {
        const res = await apiGet('protected/employer/dashboard/options', employer?.selectedLocation?.companyId ? { employerId: employer?.selectedLocation?.companyId } : {}, user.token)
        let rootOrFirst = res?.locations.filter((loc) => loc.isRootCompany)?.[0] || res?.locations?.[0]
        const selectedLocation = employer?.selectedLocation?.companyId ? employer?.selectedLocation : rootOrFirst
        dispatch(setOptions({ ...res, selectedLocation }))
        dispatch($getUserRoles(rootOrFirst))
    } catch (e) {
        dispatch(setAppError({ error: e.message || 'Error' }))
    }
}

export default employerSlice.reducer

import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/es/storage'

import serviceRequestReducer from './slices/serviceRequestSlice'
import employerReducer from './slices/employerSlice'
import appReducer from './slices/appSlice'
import dashboardReducer from './slices/dashboardSlice'
import userReducer from './slices/userSlice'

const reducers = combineReducers({
    user: userReducer,
    app: appReducer,
    employer: employerReducer,
    dashboard: dashboardReducer,
    serviceRequest: serviceRequestReducer,
})

const persistConfig = {
    key: 'employer',
    storage,
    whitelist: ['employer', 'user'],
    blacklist: [],
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
})

export const appDispatch = store.dispatch

export const persistor = persistStore(store)

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    appErrors: [],
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setAppError: (state, action) => {
            const allErrors = [...state.appErrors, action.payload?.error ?? action.payload?.message]
            const uniqueErrors = [...new Set(allErrors)]
            const nonNullErrors = uniqueErrors.filter((error) => error)
            state.appErrors = nonNullErrors.splice(-4)
        },
        deleteAppError: (state, action) => {
            state.appErrors = state.appErrors.filter((appError) => appError !== action.payload.error).splice(-4) || []
        },
    },
})

export const { setAppError, deleteAppError } = appSlice.actions

export const selectErrors = (state) => state.app.appErrors

// Thunks

export const $fetchAppErrorInformation = (e) => async (dispatch, getState) => {
    dispatch(setAppError({ error: e }))
}

export default appSlice.reducer

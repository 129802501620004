import React, { useState } from 'react'
import { apiPut } from '../../utils/api'
import { Spinner } from '@primed/hyper'
import { useDispatch } from 'react-redux'
import { setAppError } from '../../redux/slices/appSlice'
import { Link } from 'react-router-dom'

export default function ForgotPassword() {
    const dispatch = useDispatch()

    const [email, setEmail] = useState('')
    const [status, setStatus] = useState('edit')

    const requestReset = async (evt) => {
        try {
            evt.preventDefault()

            setStatus('loading')
            const response = await apiPut('public/account/password/forgot', { email })
            if (response.error) {
                setStatus('edit')
                dispatch(setAppError({ error: 'Failed to reset password. Please try aagain.' }))
            } else {
                setStatus('success')
            }
        } catch (error) {
            console.error(error)
            setStatus('edit')
        }
    }

    return (
        <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xxl-4 col-lg-5">
                        <div className="card">
                            <div className="card-header pt-4 pb-4 text-center bg-primary">
                                <Link to="/user/login">
                                    <span>
                                        <img className="login-header-logo" src="/img/prime-logo_white.png" alt="PRIME" />
                                    </span>
                                </Link>
                            </div>
                            <div className="card-body p-4">
                                <div className="text-center w-75 m-auto">
                                    <h4 className="text-dark-50 text-center mt-0 fw-bold">Forgot Password</h4>
                                    <p className="text-muted mb-4">Enter your email address and a reset link will be emailed to you</p>
                                </div>
                                <form id="forgotPasswordForm" onSubmit={requestReset}>
                                    <div className="mb-3">
                                        <label htmlFor="emailaddress" className="form-label required">
                                            Email address
                                        </label>
                                        <input
                                            tabIndex={1}
                                            className="form-control"
                                            type="email"
                                            id="emailaddress"
                                            required
                                            placeholder="Enter your email"
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value)
                                            }}
                                            autoFocus
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className="px-2 mb-3 mb-0 text-center">
                                {status === 'edit' && (
                                    <button tabIndex={2} type="submit" className="btn btn-primary" form="forgotPasswordForm" disabled={!email}>
                                        Request Reset
                                    </button>
                                )}
                                {status === 'loading' && <Spinner />}
                                {status === 'success' && (
                                    <>
                                        <button className="btn btn-success" disabled>
                                            <ion-icon name="checkmark" />
                                            <span className="ms-1">Success</span>
                                        </button>
                                        <div className="alert alert-primary mt-2" role="alert">
                                            Please check your email to complete your password reset request.
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

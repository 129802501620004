import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

// Redux
import { $logOut, selectUserId } from '../../redux/slices/userSlice'
import { $getEmployerOptions, selectEmployerLocations, selectSelectedLocation, setSelectedLocation } from '../../redux/slices/employerSlice'
import { resetServiceRequestTableSearch, selectIsServiceRequestsLoading, setServiceRequests } from '../../redux/slices/dashboardSlice'

// Components

// Styles

function AppTopNav({ username, signedIn }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [showUserMenu, setShowUserMenu] = useState(false)
    const [showLocationMenu, setShowLocationMenu] = useState(false)
    const loading = useSelector(selectIsServiceRequestsLoading)
    const locations = useSelector(selectEmployerLocations)
    const selectedLocation = useSelector(selectSelectedLocation)
    const userId = useSelector(selectUserId)

    useEffect(() => {
        document.querySelector('body').addEventListener('click', () => {
            setShowLocationMenu(false)
            setShowUserMenu(false)
        })
    }, [])

    const handleLogOut = () => {
        dispatch(
            $logOut(() => {
                navigate('/')
            })
        )
    }

    return (
        <div className="navbar-custom topnav-navbar topnav-navbar-dark">
            <div className="container-fluid">
                <Link to="/employer" className="topnav-logo">
                    <span className="topnav-logo-lg ms-2 me-5">
                        <img src="/img/prime-logo_white.png" alt="" height="20px" />
                    </span>
                    <span className="topnav-logo-sm ms-2 me-5">
                        <img src="/img/prime-logo_white.png" alt="" height="20px" />
                    </span>
                </Link>
                {selectedLocation && (
                    <ul className="list-unstyled topbar-menu float-end mb-0">
                        <li className="dropdown notification-list">
                            <span
                                className="nav-link dropdown-toggle nav-user arrow-none me-0"
                                data-bs-toggle="dropdown"
                                id="topbar-userdrop"
                                href="#"
                                role="button"
                                aria-haspopup="true"
                                aria-expanded="false"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setShowLocationMenu(!showLocationMenu)
                                }}
                            >
                                <span className="account-user-avatar">
                                    {selectedLocation?.isRootCompany ? (
                                        <ion-icon name="business-outline" style={{ fontSize: '24px' }} />
                                    ) : (
                                        <ion-icon name="location-outline" style={{ fontSize: '24px' }} />
                                    )}

                                    <br />
                                    <ion-icon name="chevron-down" style={{ fontSize: '16px', marginLeft: '3px' }} />
                                </span>
                                <span>
                                    <span className="account-user-name">{selectedLocation?.companyName}</span>
                                    <span className="account-position">Current Location</span>
                                </span>
                            </span>
                            <div
                                className={`dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown" aria-labelledby="topbar-userdrop ${
                                    showLocationMenu ? 'show' : ''
                                }`}
                            >
                                {locations?.map((loc, idx) => {
                                    if (!loc.isRootCompany) {
                                        return <template key={idx}></template>
                                    }
                                    return (
                                        <button
                                            key={idx}
                                            className="dropdown-item notify-item d-flex align-items-center"
                                            disabled={loading}
                                            onClick={() => {
                                                dispatch(setServiceRequests([]))
                                                dispatch(setSelectedLocation({ location: loc }))
                                                dispatch($getEmployerOptions())
                                                dispatch(resetServiceRequestTableSearch())
                                                setTimeout(() => {
                                                    navigate('/employer#refresh')
                                                }, 0)
                                                setShowLocationMenu(false)
                                            }}
                                        >
                                            <ion-icon name="business-outline" style={{ fontSize: '24px' }} />
                                            <span className="align-middle ms-1">{loc.companyName}</span>
                                        </button>
                                    )
                                })}
                                {locations?.map((loc, idx) => {
                                    if (!loc.isRootCompany) {
                                        return (
                                            <button
                                                key={idx}
                                                className="dropdown-item notify-item d-flex align-items-center"
                                                disabled={loading}
                                                onClick={() => {
                                                    dispatch(setServiceRequests([]))
                                                    dispatch(setSelectedLocation({ location: loc }))
                                                    dispatch($getEmployerOptions())
                                                    dispatch(resetServiceRequestTableSearch())
                                                    setTimeout(() => {
                                                        navigate('/employer#refresh')
                                                    }, 0)
                                                    setShowLocationMenu(false)
                                                }}
                                            >
                                                <ion-icon name="location-outline" style={{ fontSize: '24px' }} />
                                                <span className="align-middle ms-1">{loc.companyName}</span>
                                            </button>
                                        )
                                    } else {
                                        return null
                                    }
                                })}
                            </div>
                        </li>
                        <li className="dropdown notification-list">
                            <span
                                className="nav-link dropdown-toggle nav-user arrow-none me-0"
                                data-bs-toggle="dropdown"
                                id="topbar-userdrop"
                                href="#"
                                role="button"
                                aria-haspopup="true"
                                aria-expanded="false"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setShowUserMenu(!showUserMenu)
                                }}
                            >
                                <span className="account-user-avatar">
                                    <img src={`https://ui-avatars.com/api/?name=${username}&length=1&background=1de9b6`} alt="user" className="rounded-circle" />
                                </span>
                                <span>
                                    <span className="account-user-name">{username}</span>
                                    <span className="account-position">{signedIn ? 'Signed In' : 'No User Account'}</span>
                                </span>
                            </span>

                            <div
                                className={`dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown" aria-labelledby="topbar-userdrop ${
                                    showUserMenu ? 'show' : ''
                                }`}
                            >
                                <Link
                                    to={`/employer/settings/users/${userId}`}
                                    className="dropdown-item notify-item"
                                    onClick={() => {
                                        setShowUserMenu(false)
                                    }}
                                >
                                    <div className="d-flex align-items-center gap-1">
                                        <ion-icon name="person-outline"></ion-icon>
                                        <span>Profile</span>
                                    </div>
                                </Link>
                                <button className="dropdown-item notify-item" onClick={handleLogOut}>
                                    <div className="d-flex align-items-center gap-1">
                                        <ion-icon name="log-out-outline"></ion-icon>
                                        <span>Logout</span>
                                    </div>
                                </button>
                            </div>
                        </li>
                    </ul>
                )}
            </div>
        </div>
    )
}

export default AppTopNav

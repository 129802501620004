import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { apiPost } from '../../../../utils/api'

// Redux
import {
    $getLocationsData,
    selectCategories,
    selectClinics,
    selectJobs,
    selectProjects,
    selectPurchaseOrders,
    selectReasons,
    selectRegions,
    selectServices,
} from '../../../../redux/slices/serviceRequestSlice'
import { selectSelectedLocation } from '../../../../redux/slices/employerSlice'

// Components
import { Spinner, TabNav, TabNavButton, TabPane, Tabs } from '@primed/hyper'
import PageTitle from '../../../../components/app/PageTitle'
import TabEmployers from '../../../../components/service-request/TabEmployers'
import TabPatients from '../../../../components/service-request/TabPatients'
import TabServices from '../../../../components/service-request/TabServices'
import TabVerify from '../../../../components/service-request/TabVerify'
import TabSuccess from '../../../../components/service-request/TabSuccess'

// Styles
import './ServiceRequest.scss'
import { setAppError } from '../../../../redux/slices/appSlice'

function ServiceRequest() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { hash } = useLocation()

    const location = useSelector(selectSelectedLocation)
    const clinics = useSelector(selectClinics)
    const services = useSelector(selectServices)
    const categories = useSelector(selectCategories)
    const reasons = useSelector(selectReasons)
    const regions = useSelector(selectRegions)
    const jobs = useSelector(selectJobs)
    const projects = useSelector(selectProjects)
    const purchaseOrders = useSelector(selectPurchaseOrders)

    const [serviceRequest, setServiceRequest] = useState({})
    const [validation, setValidation] = useState('Project and Job Number are required.')
    const [showValidation, setShowValidation] = useState(false)
    const [tabIdx, setTabIdx] = useState(0)
    const [resetKey, setResetKey] = useState((Math.random() + 1).toString(36).substring(7))

    const handleSubmitRequest = async (e) => {
        e.preventDefault()
        const sr = serviceRequest
        let allTestTypeIds = []
        sr.services.forEach((ser) => {
            allTestTypeIds.push(ser.testTypeId)
            if (ser.parentTestTypeId) {
                allTestTypeIds.push(ser.parentTestTypeId)
            }
        })
        allTestTypeIds = [...new Set([...allTestTypeIds])]
        const fullServiceRequest = {
            jobNumber: sr.jobNumber,
            clinicId: sr.clinicId,
            comment: sr.comment || null,
            testReasonCode: sr.testReasonCode,
            testTypeIds: allTestTypeIds,
            oldPatientIds: sr.patients?.map((pat) => {
                if (pat.patientId) {
                    return pat.patientId
                }
                return null
            }),
            purchaseOrder: sr.po || '',
            employerId: location.companyId,
        }
        try {
            const res = await apiPost('protected/employer/service-request/', { serviceRequest: fullServiceRequest })
            if (res.success) {
                setTabIdx(tabIdx + 1)
            } else if (res.error) {
                dispatch(setAppError({ error: res.error }))
            }
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        const projectId = projects?.find(({ projectNumber }) => projectNumber === serviceRequest.project)?.projectId ?? projects[0]?.projectId
        dispatch($getLocationsData(projectId))
    }, [serviceRequest.project])

    useEffect(() => {
        if (hash.includes('refresh')) {
            setServiceRequest({})
            setTabIdx(0)
            setValidation('Project and Job Number are required.')
            setResetKey((Math.random() + 1).toString(36).substring(7))
            return navigate('/employer/service-request')
        }
    }, [hash])

    const wizardPrevious = (evt) => {
        evt.preventDefault()
        setTabIdx(tabIdx - 1)
    }

    const wizardNext = (evt) => {
        evt.preventDefault()
        executeWizardTabIdx(tabIdx)
        setTabIdx(tabIdx + 1)
    }

    const executeWizardTabIdx = (tabIdx) => {
        console.log(tabIdx)
        switch (tabIdx) {
            case 0:
                setValidation('Clinic, Visit Reason, and at least one Service are required.')
                break
            case 1:
                setValidation('At least one employee is required for a service request.')
                break
            default:
                break
        }
    }

    const showPrevious = () => {
        return tabIdx !== 0
    }

    const disablePrevious = () => {
        return tabIdx === 0
    }

    const showNext = () => {
        switch (tabIdx) {
            case 3:
                return false
            default:
                return true
        }
    }

    const disableNext = () => {
        switch (tabIdx) {
            case 0:
                if (serviceRequest.project && serviceRequest.jobNumber) {
                    return false
                }
                return true
            case 1:
                if (serviceRequest.clinicId && serviceRequest.testReasonCode && serviceRequest.services?.length) {
                    return false
                }
                return true
            case 2:
                if (serviceRequest.patients?.length) {
                    return false
                }
                return true
            default:
                return true
        }
    }

    const showSubmit = () => {
        return tabIdx === 3
    }

    const disableSubmit = () => {
        return false
    }

    return (
        <div key={resetKey}>
            <PageTitle taskTitle="Service Requests" />
            <div className="card">
                <div className="card-body">
                    <h4 className="header-title mb-3">New Service Request</h4>

                    <div id="basicwizard">
                        {!!projects.length && (
                            <>
                                <TabNav classes={['nav-pills', 'nav-justified', 'form-wizard-header', 'mb-4']}>
                                    <TabNavButton active={tabIdx === 0} classes={['d-flex', 'align-items-center', 'justify-content-center', 'rounded-0']}>
                                        <ion-icon name="location" />
                                        <span className="ms-1">Location</span>
                                    </TabNavButton>
                                    <TabNavButton active={tabIdx === 1} classes={['d-flex', 'align-items-center', 'justify-content-center', 'rounded-0']}>
                                        <ion-icon name="heart" />
                                        <span className="ms-1">Service</span>
                                    </TabNavButton>
                                    <TabNavButton active={tabIdx === 2} classes={['d-flex', 'align-items-center', 'justify-content-center', 'rounded-0']}>
                                        <ion-icon name="man" />
                                        <span className="ms-1">Employees</span>
                                    </TabNavButton>
                                    <TabNavButton active={tabIdx === 3} classes={['d-flex', 'align-items-center', 'justify-content-center', 'rounded-0']}>
                                        <ion-icon name="checkbox" />
                                        <span className="ms-1">Verify</span>
                                    </TabNavButton>
                                </TabNav>

                                <Tabs classes={['mb-3 p-5 pt-1 pb-1']}>
                                    <TabPane active={tabIdx === 0}>
                                        <TabEmployers
                                            serviceRequest={serviceRequest}
                                            setServiceRequest={setServiceRequest}
                                            regions={regions}
                                            jobs={jobs}
                                            purchaseOrders={purchaseOrders}
                                            location={location}
                                            projects={projects}
                                        />
                                    </TabPane>
                                    <TabPane active={tabIdx === 1}>
                                        <TabServices
                                            serviceRequest={serviceRequest}
                                            setServiceRequest={setServiceRequest}
                                            clinics={clinics}
                                            reasons={reasons}
                                            services={services}
                                            categories={categories}
                                            location={location}
                                        />
                                    </TabPane>
                                    <TabPane active={tabIdx === 2}>
                                        <TabPatients serviceRequest={serviceRequest} setServiceRequest={setServiceRequest} />
                                    </TabPane>
                                    <TabPane active={tabIdx === 3}>
                                        <TabVerify serviceRequest={serviceRequest} setServiceRequest={setServiceRequest} />
                                    </TabPane>
                                    <TabPane active={tabIdx === 4}>
                                        <TabSuccess />
                                    </TabPane>
                                    {tabIdx < 4 && (
                                        <ul className="list-inline wizard mb-0">
                                            <li className={`previous list-inline-item ${showPrevious() ? '' : 'd-none'}`}>
                                                <button className="btn btn-primary" onClick={(evt) => wizardPrevious(evt)} disabled={disablePrevious()}>
                                                    Previous
                                                </button>
                                            </li>
                                            <li
                                                className={`next list-inline-item float-end ${showNext() ? '' : 'd-none'}`}
                                                onMouseEnter={(e) => {
                                                    disableNext() ? setShowValidation(true) : setShowValidation(false)
                                                }}
                                                onMouseLeave={(e) => {
                                                    setShowValidation(false)
                                                }}
                                            >
                                                {/*${!(job && location && project && purchaseOrder) ? 'disabled' : ''}*/}
                                                <button className={`btn btn-primary`} onClick={wizardNext} disabled={disableNext()}>
                                                    Next
                                                </button>
                                            </li>
                                            {!!validation && showValidation && (
                                                <li className="float-end pt-1 me-3">
                                                    <span className="small text-danger">{validation}</span>
                                                </li>
                                            )}
                                            <li className={`next list-inline-item float-end ${showSubmit() ? '' : 'd-none'}`}>
                                                {/*${!(job && location && project && purchaseOrder) ? 'disabled' : ''}*/}
                                                <button className={`btn btn-success`} onClick={handleSubmitRequest} disabled={disableSubmit()}>
                                                    Submit
                                                </button>
                                            </li>
                                        </ul>
                                    )}
                                </Tabs>
                            </>
                        )}
                        {!!projects.length || (
                            <div className="text-center">
                                <Spinner />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box" />
                </div>
            </div>

            <div className="row">
                <div className="col-xxl-9 col-xl-7" />
            </div>
        </div>
    )
}

export default ServiceRequest

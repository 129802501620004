import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectSelectedLocation } from '../../redux/slices/employerSlice'
import { enums } from '@primed/util-client'

function TabEmployers({ serviceRequest, setServiceRequest, location, projects, jobs, purchaseOrders, regions }) {
    const navigate = useNavigate()

    const { role } = useSelector(selectSelectedLocation)

    const isAdmin = role === enums.PRIMED_EMPLOYER_USER_ROLE.ADMIN

    return (
        <fieldset disabled={projects.length === 0}>
            <div className="row mb-3">
                <div className="col-12">
                    <h2 className="text-center">New Service Request for {location.companyName}</h2>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-12">
                    <h4 className="text-center">Please add any tags for the service request.</h4>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-8 offset-2">
                    <div className="input-group">
                        <label className="input-group-text me-0 w-25" htmlFor="inputGroupSelect01">
                            Project
                        </label>
                        <select
                            className="form-select"
                            id="projects"
                            onChange={(e) => {
                                setServiceRequest({ ...serviceRequest, project: e.target.value })
                            }}
                            required
                            defaultValue=""
                        >
                            <option value="" disabled>
                                Select a project
                            </option>
                            {projects?.map((proj) => (
                                <option key={proj.projectNumber} value={proj.projectNumber}>
                                    {proj.projectNumber}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-8 offset-2">
                    <div className="input-group">
                        <label className="input-group-text me-0 w-25" htmlFor="inputGroupSelect01">
                            Job Number
                        </label>
                        <select
                            className="form-select"
                            id="jobs"
                            onChange={(e) => {
                                if (e.target.value === 'create-job-option') {
                                    navigate('/employer/settings/jobs/create')
                                }

                                setServiceRequest({ ...serviceRequest, jobNumber: e.target.value })
                            }}
                            required="true"
                            defaultValue=""
                            disabled={!serviceRequest.project}
                        >
                            <option value="" disabled>
                                Select a job number
                            </option>
                            {jobs?.map((job) => {
                                return job.jobNumber ? (
                                    <option key={job?.jobNumber} value={job.jobNumber}>
                                        {job.jobNumber}
                                    </option>
                                ) : (
                                    false
                                )
                            })}
                            {isAdmin && <option value="create-job-option">+ Create a new job number</option>}
                        </select>
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-8 offset-2">
                    <div className="input-group">
                        <label className="input-group-text me-0 w-25" htmlFor="inputGroupSelect01">
                            Purchase Order
                        </label>
                        <input
                            className="form-control"
                            list="pos"
                            onBlur={(e) => {
                                setServiceRequest({ ...serviceRequest, po: e.target.value })
                            }}
                        />
                        <datalist id="pos">
                            {purchaseOrders?.map((po) => (
                                <option key={po.purchaseOrder}>{po.purchaseOrder}</option>
                            ))}
                        </datalist>
                    </div>
                </div>
            </div>

            {projects.length === 0 && (
                <div className="alert alert-danger" role="alert">
                    <ion-icon name="close"></ion-icon> No projects available. Service requests must be created relative to a project. Please contact
                    <a rel="noreferrer" target="_blank" href="mailto:eme.support@primeoccmed.com">
                        EME Support
                    </a>{' '}
                    for further assistance.
                </div>
            )}
        </fieldset>
    )
}

export default TabEmployers
